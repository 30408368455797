<template>
    <v-dialog
            class="event-modal"
            v-model="show"
            max-width="1110"
            persistent
    >
        <v-card class="event-modal__body">
            <v-card-title class="event-modal__header">
                <span class="headline">{{ title }}</span>
                <i class="fas fa-times" @click="close({shouldConfirm: true})"></i>
            </v-card-title>
            <v-card-text>
                <v-container >
                    <EventForm
                       ref="form"
                       v-if="show"
                       :date="date"
                       :roomId="roomId"
                       :formStartData="formData"
                       @onCancel="close"
                       @onSubmit="submit"
                       @onUpdateData="updateData"
                       @onSubmitRecurring="submitRecurring"
                       @createRecurringEvents="createRecurringEvents"
                       @isLoading="togglePreloader"
                       @onDelete="deleteEvent"
                    />
                    <preloader :overlay="preloader"></preloader>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import EventForm from '@/components/Forms/EventForm/EventForm.vue';
import Preloader from '@/components/Shared/Preloader.vue';

export default {
  name: 'EventModal',
  components: { EventForm, Preloader },
  props: {
    value: {},
    date: {
      type: null,
      default: () => {},
    },
    roomId: {
      type: null,
      default: () => '',
    },
  },
  data: () => ({
    preloader: false,
  }),
  methods: {
    setModalConflictEvents(data) {
      this.$store.dispatch('modalConflictEvents/setShow', true);
      this.$store.dispatch('modalConflictEvents/setEvents', data);
    },
    isErrorStatus(status, callBack) {
      if (status !== 403) {
        return;
      }
      callBack();
    },
    close({ update, shouldConfirm }) {
      let answer = true;
      if (this.isEventFormChanged && shouldConfirm) {
        answer = this._confirmAction(window.app.$t('message.dataCanNotBeSaved'));
      }
      if (answer) {
        this.$emit('close');
        this.clearForm();
      }
      if (update) {
        this.updateData();
      }
    },
    submit({ form, event_meta: eventMeta, closeForm }) {
      this.togglePreloader(true);
      if (form.id) {
        this.edit({ form, closeForm });
      } else {
        this.create({ form, eventMeta });
      }
    },
    updateData() {
      this.$emit('updateData');
    },
    submitRecurring({ event_meta: eventMeta }) {
      this.togglePreloader(true);
      this.$api.events.undraftRecurringEvents(eventMeta)
        .then(() => {
          this.clearForm();
          this.updateData();
          this.$emit('close');
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        }).finally(() => {
          this.togglePreloader(false);
        });
    },
    createRecurringEvents(formData) {
      this.togglePreloader(true);
      this.$api.events.createRecurringEvents(formData)
        .then(({ result }) => {
          this.$store.dispatch('eventForm/setRecurringEvents', result.data[0].room_reservations);
        })
        .catch((error) => {
          // eslint-disable-next-line
          this.isErrorStatus(error.response.status, this.setModalConflictEvents(error.response.data));
          console.log(error);
        }).finally(() => {
          this.togglePreloader(false);
        });
    },
    /* TODO need optimization all error on all website */
    setModalActivePersons(data) {
      const { errors } = data;

      const prepareObject = {
        errors: {
          ...errors,
          teachers: errors.no_active_teachers,
          students: errors.no_active_students,
          subject_id: errors.subject_id,
        },
      };
      this.$store.dispatch('modalConflictEvents/setErrors', prepareObject);
    },
    create({ form }) {
      this.$api.events.create(form)
        .then(() => {
          this.clearForm();
          this.$emit('close');
          this.updateData();
        })
        .catch((error) => {
          this.setModalActivePersons(error.response.data);
          this.isErrorStatus(
            error.response.status,
            this.setModalConflictEvents(error.response.data),
          );
        }).finally(() => {
          this.togglePreloader(false);
        });
    },
    edit({ form, closeForm }) {
      this.$api.events.edit(form)
        .then(({ reservation }) => {
          if (closeForm) {
            this.updateData();
            this.clearForm();
            this.$emit('close');
          } else {
            const {
              // eslint-disable-next-line
              id, room, start_datetime, event_meta
            } = reservation.data;
            this.$store.dispatch('eventForm/setNewEventData', { id, room, start_datetime });
            this.$store.dispatch('eventForm/setFormChanged', false);
            this.$refs.form.changeEditableEvent(id);
            this.$refs.form.getEventsByMeta(event_meta.id);
            this.updateData();
          }
        })
        .catch((error) => {
          this.setModalActivePersons(error.response.data);
          this.isErrorStatus(
            error.response.status,
            this.setModalConflictEvents(error.response.data),
          );
        }).finally(() => {
          this.togglePreloader(false);
        });
    },
    deleteEvent(id) {
      this.togglePreloader(true);
      this.$api.events.remove(id)
        .then(() => {
          this.updateData();
          this.$emit('close');
        })
        .catch().finally(() => {
          this.clearForm();
          this.togglePreloader(false);
        });
    },
    togglePreloader(value) {
      this.preloader = value;
    },
    clearForm() {
      this.$store.dispatch('eventForm/clearData', {});
      this.$store.dispatch('modalConflictEvents/clearEvents');
      this.$store.dispatch('eventForm/setIsRecurringEventOnCreating', false);
    },
  },
  computed: {
    formData: {
      get() {
        return this.$store.getters['eventForm/getData'];
      },
    },
    isEventFormChanged: {
      get() {
        return this.$store.getters['eventForm/getIsFormChanged']
        || this.$store.getters['eventForm/getIsRecurringOnCreating'];
      },
    },
    title: {
      get() {
        return this.formData.id ? this.$t('message.edit') : this.$t('message.create');
      },
    },
    show: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss">
    .v-dialog{
        .container{
            min-height: 400px;
        }
    }
    .event-modal__body{
        position: relative;
        min-height: 700px;
    }
    .event-modal__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 35px 10px !important;
        i{
            color: #ADADAD;
            cursor: pointer;
            &:hover{
                color: $defaultColor;
            }
        }
    }
</style>
