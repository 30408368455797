<template>
  <div class="calendar__body calendar_day" :class="{disabled: isLoading}">
    <div v-if="ready"
        class="calendar_theme-second calendar__body_day fixed_sidebar_day"
    >
      <div class="fixed_sidebar">
        <div class="calendar__row calendar__row_header" :style="{width: `${170}px`}">
            <div class="calendar__header calendar__header-title">
                Time
            </div>
        </div>
        <div class="calendar__content" :style="{width: `${170}px`}">
          <div class="calendar__content_scroll">
            <v-expansion-panels multiple accordion v-model="panelsState">
                <v-expansion-panel
                    class="calendar__room-group"
                    v-for="(roomGroup, index, key) in getGroupRooms"
                    :key="key"
                >
                    <v-expansion-panel-header
                        class="calendar__room-group-title"
                        expand-icon="mdi-menu-down"
                    >
                        {{ roomGroup.name }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="calendar__room-group-content">
                        <div
                            class="calendar__room"
                            v-for="(room, index) in roomGroup.rooms"
                            :key="index"
                        >
                            <div class="calendar__room-row">
                                <div
                                  style="height: 46px"
                                  class="calendar__room-cell calendar__room-cell_name"
                                >
                                    {{ room.room_number }}
                                </div>
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
      <div
          class="fixed_sidebar_calendar_content"
      >
        <div class="calendar__row calendar__row_header" :style="{width: `${getWidthView + 170}px`}">

            <div
                class="calendar__header calendar__header_day"
                :key="index + 'time'"
                :style="{'min-width': `${configViewCalendar.widthCell}px`}"
                v-for="(time, index) in _getTimeOfDay"
            >
                <div class="calendar__date">
                    <!-- eslint-disable-next-line -->
                    {{ time.time.hour | convertNumberToDecimal }}:{{ time.time.minute | convertNumberToDecimal }}
                </div>
            </div>
        </div>
        <div class="calendar__content" :style="{width: `${getWidthView + 170}px`}">
            <v-expansion-panels multiple accordion v-model="panelsState">
                <v-expansion-panel
                    class="calendar__room-group"
                    v-for="(roomGroup, index, key) in getGroupRooms"
                    :key="key"
                >
                    <v-expansion-panel-header
                        class="calendar__room-group-title"
                        expand-icon=""
                    >

                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="calendar__room-group-content">
                        <div
                            class="calendar__room"
                            v-for="(room, index) in roomGroup.rooms"
                            :key="index"
                        >
                            <div class="calendar__room-row">
                                <div class="calendar__room-cell"
                                     @click="onCreateEvent(time, room.id)"
                                     :key="index"
                                     v-for="(time, index) in _getTimeOfDay"
                                     :style="{
                                    height: `${configViewCalendar.heightCell}px`,
                                    'min-width': `${configViewCalendar.widthCell}px`
                                    }"
                                >
                                    <drop
                                            @drop="onDrop(time, {...arguments, room_id: room.id})"
                                            class="calendar__room-drop"
                                    ></drop>
                                </div>
                                <div class="calendar__events calendar__events_day"
                                     v-for="(event, index) in room.room_reservations"
                                     :key="`${index}-events`"
                                >
                                    <!-- eslint-disable -->
                                    <div
                                        class="calendar__event-wrapper"
                                        :style="{
                                            left: `${_getPositionEventOnHours(event.timeOfDay, 'widthCell')}px`,
                                            width: `${_getPositionEventOnHours(event.durationMinutes, 'widthCell')}px`,
                                            height: `${configViewCalendar.heightCell}px`,
                                        }"
                                        :id="event.id"
                                        @click="onChangeEvent(event)"
                                        @mouseenter="_onMouseOverByCalendarEvent(event.id, $event)"
                                        @mouseleave="_onMouseLeaveByCalendarEvent"
                                    >
                                        <drag
                                                :transferData="[event]"
                                                class="calendar__event"
                                                @dragstart="onDragStart"
                                                @dragend="onDragEnd"
                                                :style="{backgroundColor: `${event.status.color}`}"
                                        >
                                            <div class="calendar__event-title">
                                                {{ event.subject ? event.subject.name : event.event_name }}<br>
                                                {{ showEventRoom ? `(Room ${event.room.room_number})` : '' }}
                                            </div>
                                        </drag>
                                        <transition name="calendar-info-event-fade">
                                            <calendar-info-event
                                                    ref="calendarEvent"
                                                    v-if="selectInfoEvent.index === event.id"
                                                    :event="event"
                                                    :position="selectInfoEvent.position"
                                                    :direction="selectInfoEvent.direction" />
                                        </transition>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Drag, Drop } from 'vue-drag-drop';
import CalendarInfoEvent from '@components/Calendar/CalendarInfoEvent.vue';
import calendarMixin from '@mixins/Calendar/calendarMixin';
import constants from '@constants';

export default {
  components: {
    Drag,
    Drop,
    CalendarInfoEvent,
  },
  mixins: [
    calendarMixin,
  ],
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
    startDate: {
      type: Object,
      default: () => this.$moment().set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }),
    },
    roomsWithEvents: {
      type: Array,
      default: () => [],
    },
    showEventRoom: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    configViewCalendar: null,
    selectInfoEvent: {
      index: null,
      position: null,
      direction: 'left',
    },
    panelsState: [],
  }),
  computed: {
    getGroupRooms() {
      const groupRoomsName = {};
      this.roomsWithEvents.map((item) => {
        if (!groupRoomsName[item.room_group]) {
          groupRoomsName[item.room_group] = {};
          groupRoomsName[item.room_group].name = item.room_group;
          groupRoomsName[item.room_group].rooms = [];
        }
        return groupRoomsName[item.room_group].rooms.push(this.getRoomEventsWithTime(item));
      });
      return groupRoomsName;
    },
    getWidthView() {
      return this._getTimeOfDay.length * this.configViewCalendar.widthCell;
    },
    getOpenGroupRooms: {
      get() {
        const lengthGroupRooms = Object.keys(this.getGroupRooms).length;
        const panelsOpen = [];
        for (let i = 0; i < lengthGroupRooms; i += 1) {
          panelsOpen.push(i);
        }
        return panelsOpen;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  watch: {
    getOpenGroupRooms: {
      handler(val) {
        this.panelsState = val;
      },
    },
  },
  mounted() {
    const lengthGroupRooms = Object.keys(this.getGroupRooms).length;
    const panelsOpen = [];
    for (let i = 0; i < lengthGroupRooms; i += 1) {
      panelsOpen.push(i);
    }
    this.panelsState = panelsOpen;
  },
  methods: {
    getRoomEventsWithTime(room) {
      const currentRoom = room;
      const getTime = this._createTime(this.configViewCalendar
        ? this.configViewCalendar.startTime : this.defaultSetting.startTime);

      currentRoom.room_reservations = currentRoom.room_reservations.map((item) => {
        const momentEventStartDay = this.$moment(item.start_datetime, constants.dateTimeFormat);
        const momentEventEndDate = this.$moment(item.end_datetime, constants.dateTimeFormat);

        return {
          ...item,
          timeOfDay: Math.abs(momentEventStartDay.diff(this._changeDate({
            date: momentEventStartDay,
            time: getTime.time,
          }), 'minutes')),
          durationMinutes: Math.abs(momentEventEndDate.diff(momentEventStartDay, 'minutes')),
        };
      });

      return currentRoom;
    },
    onDragStart(event) {
      document.getElementById(`${event[0].id}`).classList.add('active');
      document.querySelector('.calendar__events').classList.add('pointer-events_none');
    },
    onDragEnd(event) {
      document.getElementById(`${event[0].id}`).classList.remove('active');
      document.querySelector('.calendar__events').classList.remove('pointer-events_none');
    },
    onDrop({ time }, data) {
      const day = this._changeDate({ date: this.startDate, time });
      const event = data[0][0];
      event.room.id = data.room_id;
      this.$emit('onDropEvent', { day, dropEvent: event });
    },
    onCreateEvent({ time }, roomId) {
      const selectedDay = this._changeDate({ date: this.startDate, time });
      this.$emit('onCreateEvent', { day: selectedDay, roomId });
    },
    onChangeEvent(event) {
      this.$emit('onChangeEvent', event);
    },
  },
};
</script>
