export default {
  data: () => ({
  }),
  computed: {
    isRecurringListErrors() {
      return this.getRecurringEvents.some((item) => {
        if (item.errors) {
          return Object.keys(item.errors).length > 0;
        }
        return false;
      });
    },
    getRecurringEvents: {
      get() {
        return this.$store.getters['eventForm/getRecurringEvents'];
      },
    },
  },
  methods: {
    getEventsByMeta(eventMeta) {
      this.$api.events.getEventsByMeta(eventMeta)
        .then(({ result }) => {
          this.$store.dispatch('eventForm/setRecurringEvents', result.data[0].room_reservations);
        }).catch();
    },
    deleteDrafts(ids) {
      if (this._confirmAction(this.$t('message.doYouReallyWantToDeleteEvents'))) {
        this.$emit('isLoading', true);
        this.$api.events.removeMultiply(ids)
          .then(() => {
            this.$store.dispatch('eventForm/removeRecurringEvents', ids);
            this.$emit('isLoading', false);
            if (ids.some(id => id === this.form.id)) {
              if (this.getRecurringEvents.length > 0) {
                this.changeEditableEvent(this.getRecurringEvents[0].id);
              } else if (this.getIsRecurringOnCreating) {
                this.backToRecurring();
              } else {
                this.cancel({ shouldConfirm: false });
              }
            }
            if (!this.getIsRecurringOnCreating) {
              this.$emit('onUpdateData');
            }
          })
          .catch().finally(() => {
            this.$emit('isLoading', false);
          });
      }
    },
  },
};
