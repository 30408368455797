<template>
    <div class="common-content">
        <div class="left-sidebar">
            <slot name="sidebar"></slot>
        </div>
        <slot name="content"></slot>
        <modals-conflict-events/>
    </div>
</template>

<script>
import ModalsConflictEvents from '@modals/ModalsConflictEvents/ModalsConflictEvents.vue';

export default {
  name: 'CommonContent',
  components: {
    ModalsConflictEvents,
  },
};
</script>

<style lang="scss">
    .left-sidebar{
        background-color: #fff;
        width: 368px;
        padding: 50px;
        border: $defaultBorder;
    }
    .common-content{
        position: relative;
        height: 100%;
        display: flex;
        align-items: stretch;
    }
</style>
