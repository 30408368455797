<template>
    <div v-if="loaded" class="calendar_week_content">
        <div class="calendar__row calendar__row_header">
            <div
                    class="calendar__header"
                    :key="index"
                    v-for="(day, index) in getDaysHeaderOfWeek"
                    :style="{width: `${getWidthColumnCalendar}%`}"
                    :class="{'calendar__header_current': getCurrentDayWithFormat === day.aliasDate}"
            >
                <div class="calendar__title">
                    {{ day.alias }}
                </div>
                <div class="calendar__date">
                    {{ day.aliasDate }}
                </div>
            </div>
        </div>
        <div class="calendar__content">
            <div
                class="calendar__row"
                :key="index"
                :style="{height: `${configViewCalendar.heightCell}px`}"
                v-for="(time, index) in _getTimeOfDay"
            >
                <div class="calendar__times">
                    <!-- eslint-disable-next-line -->
                    {{ time.time.hour | convertNumberToDecimal }}:{{ time.time.minute | convertNumberToDecimal }}
                </div>
                <div
                    class="calendar__header"
                    :key="index"
                    v-for="(day, index) in getDaysEventOfWeek[index]"
                    @click="onCreateEvent(day)"
                    :style="{width: `${getWidthColumnCalendar}%`}"
                >
                    <drop class="calendar__cell" @drop="onDrop(day, ...arguments)"></drop>
                </div>
            </div>
            <div class="calendar__events">
                <!-- eslint-disable -->
                <div
                        class="calendar__event-wrapper"
                        :key="index"
                        :style="{
                        top: `${_getPositionEventOnHours(event.timeOfDay, 'heightCell')}px`,
                        left: `${event.dayOfWeek * getWidthColumnCalendar}%`,
                        width: `${getWidthColumnCalendar}%`,
                        height: `${_getPositionEventOnHours(event.durationMinutes, 'heightCell')}px`,
                    }"
                        :class="{'calendar-info-event_show': selectInfoEvent.index === index}"
                        v-for="(event, index) in getWeekEvents"
                        :id="event.id"
                        @click="onChangeEvent(event)"
                        @mouseenter="_onMouseOverByCalendarEvent(index, $event)"
                        @mouseleave="_onMouseLeaveByCalendarEvent"
                >
                    <drag
                            :transferData="[event]"
                            class="calendar__event"
                            @dragstart="onDragStart"
                            @dragend="onDragEnd"
                            :style="{backgroundColor: `${event.status.color}`}"
                    >
                        <div class="calendar__event-title">
                            {{ event.subject ? event.subject.name : event.event_name }} <br>
                            {{ showEventRoom ? `(Room ${event.room.room_number})` : '' }}
                        </div>
                    </drag>
                    <transition name="calendar-info-event-fade">
                        <calendar-info-event
                                ref="calendarEvent"
                                v-if="selectInfoEvent.index === index"
                                :event="event"
                                :position="selectInfoEvent.position"
                                :direction="selectInfoEvent.direction" />
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Drag, Drop } from 'vue-drag-drop';
import CalendarInfoEvent from '@components/Calendar/CalendarInfoEvent.vue';
import calendarMixin from '@mixins/Calendar/calendarMixin';
import constants from '@constants';

export default {
  components: {
    Drag,
    Drop,
    CalendarInfoEvent,
  },
  mixins: [
    calendarMixin,
  ],
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
    startDate: {
      type: Object,
      default: () => this.$moment().set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }),
    },
    events: {
      type: Array,
      default: () => [],
    },
    view: {
      type: String,
      default: 'week',
    },
    showEventRoom: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    configViewCalendar: null,
    loaded: false,
    selectInfoEvent: {
      index: null,
      position: null,
      direction: 'left',
    },
  }),
  mounted() {
    this._setConfigViewCalendar(this.defaultSetting);
  },
  computed: {
    getWeekEvents() {
      return this.getEvents.filter(item => item.dayOfWeek >= 0);
    },
    getEvents() {
      const getTime = this._createTime(this.defaultSetting.startTime);

      return this.events.map((item) => {
        const momentEventStartDay = this.$moment(item.start_datetime, constants.dateTimeFormat);
        const momentEventEndDate = this.$moment(item.end_datetime, constants.dateTimeFormat);

        return {
          ...item,
          timeOfDay: Math.abs(momentEventStartDay.diff(this._changeDate({
            date: momentEventStartDay,
            time: getTime.time,
          }), 'minutes')),
          durationMinutes: Math.abs(momentEventEndDate.diff(momentEventStartDay, 'minutes')),
          dayOfWeek: momentEventEndDate.clone().startOf('day').diff(this.startDate, 'days'),
        };
      });
    },
    getDaysEventOfWeek() {
      const events = [];
      this._getTimeOfDay.map(item => events.push(this.getDaysOfWeek(item.time)));
      return events;
    },
    getDaysHeaderOfWeek() {
      return this.getDaysOfWeek();
    },
    getCurrentDayWithFormat() {
      return this.$moment().format(this.configViewCalendar.formatDate);
    },
    getCurrentNumberDayOfWeek() {
      return Number(this.startDate.day());
    },
    getWidthColumnCalendar() {
      return 100 / this.configViewCalendar.showDays;
    },
  },
  methods: {
    onCreateEvent(selectedDay) {
      this.$emit('onCreateEvent', { day: selectedDay.currentDate });
    },
    onChangeEvent(event) {
      this.$emit('onChangeEvent', event);
    },
    onDragStart(event) {
      document.getElementById(`${event[0].id}`).classList.add('active');
      document.querySelector('.calendar__events').classList.add('pointer-events_none');
    },
    onDragEnd(event) {
      document.getElementById(`${event[0].id}`).classList.remove('active');
      document.querySelector('.calendar__events').classList.remove('pointer-events_none');
    },
    onDrop({ currentDate }, dropEvent) {
      this.$emit('onDropEvent', { day: currentDate, dropEvent: dropEvent[0] });
    },
    getDaysOfWeek(time) {
      const newDayOfWeek = [];
      let numberDayOfWeek = this.getCurrentNumberDayOfWeek ? this.getCurrentNumberDayOfWeek - 1 : 6;

      for (let day = 0; day < this.configViewCalendar.showDays; day += 1) {
        newDayOfWeek.push(this.createDay({
          numberDayOfWeek,
          day,
          time,
        }));
        numberDayOfWeek = this.checkAndChangeNumberDay(numberDayOfWeek);
      }

      return newDayOfWeek;
    },
    checkAndChangeNumberDay(numberDay) {
      let numberDayOfWeek = numberDay;
      const showDays = this.configViewCalendar.showDays - 1;

      if (numberDay === showDays) {
        numberDayOfWeek = 0;
      } else {
        numberDayOfWeek += 1;
      }

      return numberDayOfWeek;
    },
    createDay({ numberDayOfWeek, day, time }) {
      const date = this.startDate;
      const currentDate = this._changeDate({ date, day, time });

      return {
        alias: this.configViewCalendar.namesDayOfWeek[numberDayOfWeek],
        aliasDate: currentDate.format(this.configViewCalendar.formatDate),
        currentDate,
      };
    },
  },
  watch: {
    getEvents: {
      handler() {
        const weekEvents = this.getWeekEvents;
        if (weekEvents.length && !this.ready) {
          const newSettings = { ...this.defaultSetting, ...this._getBounds(weekEvents) };
          if (JSON.stringify(this.defaultSetting) !== JSON.stringify(newSettings)) {
            this.defaultSetting = newSettings;
            this._setConfigViewCalendar(this.defaultSetting);
          }
        }
        this.loaded = true;
      },
    },
  },
};
</script>
