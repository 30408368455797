<template>
    <v-menu
            v-model="show"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
    >
        <template v-slot:activator="{ on }">
            <v-text-field
                    :disabled="disabled"
                    :value="textFieldVal"
                    :rules="rules || []"
                    outlined
                    :id="customId"
                    :placeholder="placeholder"
                    append-icon="calendar_today"
                    readonly
                    v-on="on"
            ></v-text-field>
            <label class="label" :for="customId">
                {{label}}
                <i class="warning-icon">!</i>
            </label>
        </template>
        <v-date-picker
                :locale="getLang"
                color="#F88D4C"
                no-title
                :min="min || $moment().format('YYYY-MM-DD')"
                first-day-of-week="1"
                scrollable
                v-model="input"
                @input="show = false"
        />
    </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
    textFieldVal: {
      type: String,
    },
    rules: {
      type: Array,
    },
    min: {
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    customId: {
      type: String,
    },
  },
  data: () => ({
    show: false,
  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped>

</style>
