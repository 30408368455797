<template>
    <div class="wrapper">
        <CommonContent>
            <template #sidebar>
                <calendar-picker
                        @dayClick="changePeriod"
                        :currentDate="getCurrentDate"
                        :mode="getPickerMode"/>
                <rooms v-if="isLoadedRooms"/>
            </template>
            <template #content>
                <Calendar
                        @getData="getData"
                        @prevPeriod="changePeriod"
                        @nextPeriod="changePeriod"
                        @changeEvent="editEvent"
                        @createEvent="createEvent"
                        @onDropEvent="onDropEvent"
                        :disableCreate="!_$can('c', 'event') && !_$can('c', 'event_reservation')"
                        :disableUpdate="!_$can('u', 'event')"
                        :showPeriod="!isMultiRoomsSelected"
                        :roomsWithEvents="selectedRoomsSchedule"
                        :selectedRooms="selectedRooms"
                        :currentDate="getCurrentDate"
                        :view="getCalendarType"
                        :isLoading="isAppLoading"
                />
            </template>
        </CommonContent>
        <event-modal
                v-model="modal.show"
                :date="modal.date"
                :roomId="modal.roomId"
                @close="hideModal"
                @updateData="getData"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Calendar from '@components/Calendar/Calendar.vue';
import CommonContent from '@components/CommonContent/CommonContent.vue';
import CalendarPicker from '@components/CalendarPicker/CalendarPicker.vue';
import Rooms from '@components/RoomsTree/RoomsTree.vue';
import constants from '@/constants';
import EventModal from '@/components/Modals/EventModal.vue';

export default {
  components: {
    Calendar, CommonContent, CalendarPicker, Rooms, EventModal,
  },
  data: () => ({
    modal: {
      show: false,
      date: '',
      roomId: '',
    },
    editableEvent: {},
  }),
  methods: {
    getData() {
      this.$api.roomSchedule.getSchedule();
    },
    setModalConflictEvents(data) {
      this.$store.dispatch('modalConflictEvents/setShow', true);
      this.$store.dispatch('modalConflictEvents/setEvents', data);
    },
    setModalActivePersons(data) {
      const { errors } = data;

      const prepareObject = {
        errors: {
          ...errors,
          teachers: errors.no_active_teachers,
          students: errors.no_active_students,
          subject_id: errors.subject_id,
        },
      };
      this.$store.dispatch('modalConflictEvents/setErrors', prepareObject);
    },
    hideModal() {
      this.modal.show = false;
      this.modal.date = '';
      this.modal.roomId = '';
    },
    changePeriod(date) {
      this.$store.dispatch('roomSchedule/setCurrentDate', date);
    },
    createEvent({ day, roomId }) {
      this.modal.show = true;
      this.modal.date = day;
      this.modal.roomId = this.isWeek && this.selectedRooms[0]
        ? this.selectedRooms[0].id : roomId;
    },
    editEvent(id) {
      this.$api.events.getById(id).then((data) => {
        this._setEvent(data);
        this.modal.show = true;
      });
    },
    onDropEvent({ day, dropEvent }) {
      this.$store.dispatch('global/setAppLoading', true);
      const event = {
        ...dropEvent,
        date_reservation: this.$moment(day).format(constants.backendDateFormat),
        time_from: this.$moment(day).format('YYYY-MM-DD HH:mm'),
        time_to: this.$moment(day).add(dropEvent.durationMinutes, 'minutes').format('YYYY-MM-DD HH:mm'),
      };

      this.$api.events.edit(this.prepareData(event)).then(() => {
        this.getData();
      }).catch(({ response }) => {
        if (response.data.errors) {
          this.setModalActivePersons(response.data);
          this.setModalConflictEvents(response.data);
          this.$store.dispatch('modalConflictEvents/setIsShouldBeCleared', true);
          this.$store.dispatch('global/setAppLoading', false);
        }
      });
    },
    prepareData(data, forForm) {
      return {
        ...data,
        category_id: data.category.id || null,
        status_id: data.status.id || null,
        room_id: data.room.id || null,
        subject_id: data.subject ? data.subject.id : null,
        students: forForm ? data.students : data.students.map(({ id }) => id),
        teachers: forForm ? data.teachers : data.teachers.map(({ id }) => id),
        start_datetime: this.$moment(data.time_from, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'),
        end_datetime: this.$moment(data.time_to, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss'),
      };
    },
  },
  computed: {
    ...mapGetters({
      selectedRoomsSchedule: 'roomSchedule/getSelectedRooms',
      rooms: 'rooms/getRooms',
      selectedRooms: 'rooms/getSelectedRooms',
    }),
    isLoadedRooms() {
      return this.$store.getters['rooms/isLoaded'];
    },
    getCurrentDate() {
      return this.$store.getters['roomSchedule/getCurrentDate'];
    },
    getPickerMode() {
      const roomsLength = this.selectedRooms.length;
      return roomsLength < 2
        ? constants.calendarPickerMods.range : constants.calendarPickerMods.single;
    },
    isMultiRoomsSelected() {
      return this.selectedRooms.length > 1;
    },
    getCalendarType() {
      return this.isMultiRoomsSelected
        ? constants.calendarViews.day : constants.calendarViews.week;
    },
    isWeek() {
      return this.getCalendarType === constants.calendarViews.week;
    },
  },
};
</script>
