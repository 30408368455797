export default {
  data: () => ({
    showTeachersError: false,
  }),
  computed: {
    getTeachers: {
      get() {
        return this.$store.getters['teachers/getTeachers'];
      },
    },
    teachersIsSelected() {
      return this.form.teachers.length !== 0;
    },
    filtredTeachers() {
      let teachers = [];

      if (this.isAdministrator()) {
        return this.getTeachers;
      }

      if (!this.isTeacher() && !this.isStudent()) {
        teachers = this.getTeachers.filter((teacher) => {
          let isValid = true;
          this.form.teachers.forEach((item) => {
            if (item.id === teacher.id) {
              isValid = false;
            }
          });
          return isValid;
        });
      } else if (this.isTeacher()) {
        teachers = [this.$store.getters['auth/getUser']];
      } else {
        teachers = [];
      }
      return teachers;
    },
  },
  methods: {
    isTeacher() {
      const { roles } = this.$store.getters['auth/getUser'];
      return (roles.findIndex(role => (role.name === 'teacher')) !== -1);
    },
    isStudent() {
      const { roles } = this.$store.getters['auth/getUser'];
      return (roles.findIndex(role => (role.name === 'student')) !== -1);
    },
    isAdministrator() {
      const { roles } = this.$store.getters['auth/getUser'];
      return (roles.findIndex(role => (role.name === 'administrator')) !== -1);
    },
    teachersChanged(elem) {
      const teacherIndex = this.form.teachers.findIndex(item => item.id === elem.id);
      if (teacherIndex === -1) {
        this.form.teachers.push(elem);
        this.showTeachersError = false;
      }
      if (this.form.teachers.length > 0) {
        this.showStudentsError = false;
        this.showTeachersError = false;
      }
      if (this.form.students.length > 0) {
        this.showStudentsError = false;
        this.showTeachersError = false;
      }
    },
    teacherRemove(id) {
      this.form.teachers = this.form.teachers.filter(item => item.id !== id);
    },
  },
};
