export default {
  data: () => ({
    configViewCalendar: null,
    fixedSidebar: null,
    ready: false,
    defaultSetting: {
      namesDayOfWeek: [
        window.app.$t('message.monday'),
        window.app.$t('message.thuesday'),
        window.app.$t('message.wednesday'),
        window.app.$t('message.thursday'),
        window.app.$t('message.friday'),
        window.app.$t('message.saturday'),
        window.app.$t('message.sunday'),
      ],
      showDays: 7,
      heightCell: 46,
      widthCell: 50,
      formatDate: 'DD.MM',
      startTime: 7.5,
      endTime: 21,
      interval: 0.5,
    },
  }),
  mounted() {
  },
  computed: {
    _getTimeOfDay() {
      const timesOfDay = [];
      for (
        let time = this.configViewCalendar.startTime;
        time <= this.configViewCalendar.endTime;
        time += this.configViewCalendar.interval) {
        timesOfDay.push(this._createTime(time));
      }

      return timesOfDay;
    },
  },
  methods: {
    _setConfigViewCalendar(settings) {
      this.configViewCalendar = Object.assign(settings, this.settings || {});
    },
    _getBounds(rooms) {
      let start = this.defaultSetting.startTime;
      let end = this.defaultSetting.endTime;
      /* eslint-disable */
      rooms.map((room) => {
        if (room.room_reservations && room.room_reservations.length) {
          room.room_reservations.map((reservation) => {
            if (reservation.start_datetime) {
              let startHour = parseInt(this.$moment(reservation.start_datetime).format('HH'));
              let endHour = parseInt(this.$moment(reservation.end_datetime).format('HH'));
              start = (startHour < start) ? startHour : start;
              end = (endHour < end) ? end : endHour;
            }
          });
        }
        if (room.start_datetime) {
          let startHour = parseInt(this.$moment(room.start_datetime).format('HH'));
          let endHour = parseInt(this.$moment(room.end_datetime).format('HH'));
          start = (startHour < start) ? startHour : start;
          end = (endHour < end) ? end : endHour;
        }
      });
      /* eslint-enable */
      if (end < 24) {
        end += 1;
      }

      return {
        startTime: start,
        endTime: end,
      };
    },
    _createTime(data) {
      const times = data.toFixed(1).split('.');
      const calcMinutes = times[1] / 10 * 60;

      return {
        time: {
          hour: +times[0],
          minute: calcMinutes,
        },
      };
    },
    _getPositionEventOnHours(minutes, cellSize) {
      const cellMinutes = 60 * this.configViewCalendar.interval;
      return minutes * this.configViewCalendar[cellSize] / cellMinutes;
    },
    _changeDate({ date, time, day }) {
      const getHour = (time && time.hour) || 0;
      const getMinute = (time && time.minute) || 0;
      const getDay = day || 0;

      return this.$moment(date).set({
        hour: getHour,
        minute: getMinute,
        second: 0,
        millisecond: 0,
      }).add(getDay, 'days');
    },
    handleCalendarScroll($event) {
      // eslint-disable-next-line
      this.fixedSidebar.style.top = '-' + $event.target.scrollTop + 'px';
    },
    _onMouseOverByCalendarEvent(index, $event) {
      this.selectInfoEvent.index = index;

      setTimeout(() => {
        document.querySelector('.calendar').classList.add('static-header');
        const html = document.getElementsByTagName('html')[0];
        const diffHtmlScroll = html.scrollHeight - html.scrollTop - html.clientHeight;
        const calendarEventWidth = this.$refs.calendarEvent[0].$el.clientWidth;
        const calendarEventHeight = this.$refs.calendarEvent[0].$el.clientHeight;
        const { innerWidth, innerHeight } = window;

        const heightWithScoll = innerHeight + diffHtmlScroll;

        const eventElem = document.elementFromPoint($event.clientX, $event.clientY)
          .getBoundingClientRect();
        const isEventHorizontalFit = (eventElem.x + eventElem.width + calendarEventWidth + 30)
            > innerWidth;
        const isEventVerticalFit = (eventElem.y + calendarEventHeight) > heightWithScoll;
        const eventPosition = {
          left: isEventHorizontalFit ? `${eventElem.x - calendarEventWidth}px` : `${eventElem.x + eventElem.width}px`,
          top: isEventVerticalFit
            ? `${$event.clientY - calendarEventHeight}px`
            : `${$event.clientY - calendarEventHeight / 2}px`,
        };
        this.selectInfoEvent.direction = isEventHorizontalFit ? 'right' : 'left';
        this.selectInfoEvent.position = eventPosition;
      }, 10);
    },
    _onMouseLeaveByCalendarEvent() {
      document.querySelector('.calendar').classList.remove('static-header');
      this.selectInfoEvent.index = null;
      this.selectInfoEvent.position = null;
      this.selectInfoEvent.direction = null;
    },
  },
  watch: {
    roomsWithEvents: {
      handler(rooms) {
        if (rooms.length) {
          this.defaultSetting = { ...this.defaultSetting, ...this._getBounds(rooms) };
          this._setConfigViewCalendar(this.defaultSetting);
          this.ready = true;
        }
      },
    },
    ready: {
      handler(val) {
        if (val === true) {
          setTimeout(() => {
            document.querySelector('.fixed_sidebar_calendar_content').addEventListener('scroll', this.handleCalendarScroll);
            this.fixedSidebar = document.querySelector('.fixed_sidebar_day .fixed_sidebar .calendar__content_scroll');
          }, 500);
        }
      },
    },
  },
};
