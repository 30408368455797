<template>
    <div
            class="calendar-info-event-wrapper styled-scroll"
            :class="getDirectionClass"
            :style="position"
    >
        <div :class="['calendar-info-event', !openGroups.length ? 'arrow' : '']" >
            <div class="calendar-info-event__times">
                <!-- eslint-disable -->
                <div class="calendar-info-event__times-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M8.00006 16C5.9334 16 3.9334 15.2 2.3334 13.6667C-0.799935 10.5333 -0.799935 5.46667 2.3334 2.33334C5.46673 -0.799996 10.5334 -0.799996 13.6667 2.33334C16.8001 5.46667 16.8001 10.5333 13.6667 13.6667C12.0667 15.2 10.0667 16 8.00006 16ZM8.00006 1.2C6.26673 1.2 4.5334 1.86667 3.20007 3.2C0.533398 5.86667 0.533398 10.1333 3.20007 12.8C5.86673 15.4667 10.1334 15.4667 12.8001 12.8C15.4667 10.1333 15.4667 5.86667 12.8001 3.2C11.4667 1.86667 9.7334 1.2 8.00006 1.2Z" fill="#ADADAD"/>
                        <path d="M10.7332 11.9333C10.5332 11.9333 10.3999 11.8667 10.2666 11.7333L7.53324 8.4C7.46657 8.26666 7.3999 8.13333 7.3999 8V4.86666C7.3999 4.53333 7.66657 4.26666 7.9999 4.26666C8.33324 4.26666 8.5999 4.53333 8.5999 4.86666V7.8L11.1999 11C11.3999 11.2667 11.3999 11.6667 11.1332 11.8667C10.9999 11.9333 10.8666 11.9333 10.7332 11.9333Z" fill="#ADADAD"/>
                    </svg>
                </div>
                <div class="calendar-info-event__times-date">
                    <div class="calendar-info-event__times-start-end">
                        {{ getStartDateByFormat }} - {{ getEndDateByFormat }}
                    </div>
                    <div class="calendar-info-event__times-current">
                        {{ getDateFullDate.toLowerCase() }}
                    </div>
                </div>
                <div class="calendar-info-event__times-duration">
                    {{ event.durationMinutes }} min
                </div>
            </div>
            <div class="calendar-info-event__fields">
                <div v-if="event.event_name" class="calendar-info-event__field">
                    <div class="calendar-info-event__field-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                            <path d="M12.9231 2.6H9.10489V1.5C9.10489 0.7 8.41958 0 7.63636 0H6.46154C5.58042 0 4.8951 0.7 4.8951 1.5V2.6H1.17483C0.489511 2.6 0 3.1 0 3.8V14.9C0 15.5 0.489511 16 1.17483 16H12.9231C13.5105 16 14.0979 15.5 14.0979 14.9V3.8C14 3.1 13.5105 2.6 12.9231 2.6ZM6.16783 1.5C6.16783 1.3 6.26573 1.2 6.46154 1.2H7.63636C7.83217 1.2 7.93007 1.3 7.93007 1.5V3.7C7.93007 3.9 7.73427 4 7.63636 4H6.46154C6.26573 4 6.16783 3.9 6.16783 3.7V1.5ZM12.8252 14.8H1.17483V3.9H4.8951C4.99301 4.7 5.58042 5.3 6.36364 5.3H7.53846C8.32168 5.3 9.00699 4.7 9.00699 3.9H12.7273V14.8H12.8252Z" fill="#ADADAD"/>
                            <path d="M3.62257 6.2C3.32886 6.2 3.03516 6.5 3.03516 6.8C3.03516 7.1 3.32886 7.4 3.62257 7.4H10.2799C10.5736 7.4 10.8673 7.1 10.8673 6.8C10.8673 6.5 10.5736 6.2 10.2799 6.2H3.62257Z" fill="#ADADAD"/>
                            <path d="M10.3778 8.89999H3.62257C3.32886 8.89999 3.03516 9.19999 3.03516 9.49999C3.03516 9.79999 3.32886 10.1 3.62257 10.1H10.2799C10.5736 10.1 10.8673 9.79999 10.8673 9.49999C10.8673 9.19999 10.6715 8.89999 10.3778 8.89999Z" fill="#ADADAD"/>
                            <path d="M10.3778 11.6H3.62257C3.32886 11.6 3.03516 11.9 3.03516 12.2C3.03516 12.5 3.32886 12.8 3.62257 12.8H10.2799C10.5736 12.8 10.8673 12.5 10.8673 12.2C10.8673 11.9 10.6715 11.6 10.3778 11.6Z" fill="#ADADAD"/>
                        </svg>
                    </div>
                    <div class="calendar-info-event__field-text">
                        {{ event.event_name }}
                    </div>
                </div>
                <div v-if="event.subject" class="calendar-info-event__field">
                    <div class="calendar-info-event__field-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M15.7333 0.133333C15.6 0 15.4 0 15.2667 0L5.93333 2.33333C5.66667 2.4 5.46667 2.66667 5.46667 2.93333V11.6667C4.86667 11.3333 4.13333 11.1333 3.33333 11.1333C1.46667 11.1333 0 12.2 0 13.6C0 15 1.46667 16 3.33333 16C5.2 16 6.66667 14.9333 6.66667 13.5333C6.66667 13.4667 6.66667 13.4 6.66667 13.2667C6.66667 13.2 6.66667 13.1333 6.66667 13.1333V6.53333L14.7333 4.6V9.8C14.1333 9.46667 13.4 9.26667 12.6 9.26667C10.7333 9.26667 9.26667 10.3333 9.26667 11.7333C9.26667 13.1333 10.7333 14.2 12.6 14.2C14.4667 14.2 15.9333 13.1333 15.9333 11.7333V0.6C16 0.4 15.9333 0.266667 15.7333 0.133333ZM3.33333 14.7333C2.06667 14.7333 1.2 14.0667 1.2 13.5333C1.2 12.9333 2.06667 12.3333 3.33333 12.3333C4.6 12.3333 5.46667 13 5.46667 13.5333C5.46667 14.1333 4.6 14.7333 3.33333 14.7333ZM6.73333 5.26667V3.4L14.8 1.4V3.33333L6.73333 5.26667ZM12.6667 12.9333C11.4 12.9333 10.5333 12.2667 10.5333 11.7333C10.5333 11.1333 11.4 10.5333 12.6667 10.5333C13.9333 10.5333 14.8 11.2 14.8 11.7333C14.8 12.2667 13.8667 12.9333 12.6667 12.9333Z" fill="#ADADAD"/>
                        </svg>
                    </div>
                    <div  class="calendar-info-event__field-text">
                        {{ event.subject.name }}
                    </div>
                </div>
                <div v-if="event.teachers && event.teachers.length" class="calendar-info-event__field">
                    <div class="calendar-info-event__field-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                            <path d="M8 9.20378C6.8 9.20378 5.66667 8.72651 4.8 7.84014C3.06667 6.06742 3.06667 3.1356 4.8 1.36287C6.53333 -0.409857 9.4 -0.409857 11.1333 1.36287C12.8667 3.1356 12.8667 6.06742 11.1333 7.90832C10.3333 8.72651 9.2 9.20378 8 9.20378ZM8 1.29469C7.2 1.29469 6.33333 1.6356 5.66667 2.24923C4.4 3.54469 4.4 5.65832 5.66667 6.95378C6.26667 7.56742 7.06667 7.90832 8 7.90832C8.86667 7.90832 9.66667 7.56742 10.3333 6.95378C11.6 5.65832 11.6 3.54469 10.3333 2.24923C9.66667 1.56742 8.8 1.29469 8 1.29469Z" fill="#ADADAD"/>
                            <path d="M15.4 15H0.6C0.266667 15 0 14.7273 0 14.3864C0 12.9545 1.4 10.5682 3.66667 9.20453C3.86667 9.06817 4.2 9.06817 4.4 9.27272C5.46667 10.1591 6.66667 10.5682 8 10.5682C9.33333 10.5682 10.6 10.0909 11.6 9.27272C11.8 9.13635 12.0667 9.06817 12.3333 9.20453C14.6667 10.5682 16 12.9545 16 14.3864C16 14.7273 15.7333 15 15.4 15ZM1.33333 13.7045H14.6667C14.3333 12.75 13.4667 11.3864 12.0667 10.4318C10.8667 11.3182 9.46667 11.7273 8 11.7273C6.53333 11.7273 5.13333 11.25 3.93333 10.4318C2.53333 11.4545 1.66667 12.8182 1.33333 13.7045Z" fill="#ADADAD"/>
                        </svg>
                    </div>
                    <div class="calendar-info-event__field-text">
                        {{ getTeachers(event.teachers) }}
                    </div>
                </div>
                <div class="calendar-info-event__field">
                    <div class="calendar-info-event__field-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
                            <path d="M8 9.20378C6.8 9.20378 5.66667 8.72651 4.8 7.84014C3.06667 6.06742 3.06667 3.1356 4.8 1.36287C6.53333 -0.409857 9.4 -0.409857 11.1333 1.36287C12.8667 3.1356 12.8667 6.06742 11.1333 7.90832C10.3333 8.72651 9.2 9.20378 8 9.20378ZM8 1.29469C7.2 1.29469 6.33333 1.6356 5.66667 2.24923C4.4 3.54469 4.4 5.65832 5.66667 6.95378C6.26667 7.56742 7.06667 7.90832 8 7.90832C8.86667 7.90832 9.66667 7.56742 10.3333 6.95378C11.6 5.65832 11.6 3.54469 10.3333 2.24923C9.66667 1.56742 8.8 1.29469 8 1.29469Z" fill="#ADADAD"/>
                            <path d="M15.4 15H0.6C0.266667 15 0 14.7273 0 14.3864C0 12.9545 1.4 10.5682 3.66667 9.20453C3.86667 9.06817 4.2 9.06817 4.4 9.27272C5.46667 10.1591 6.66667 10.5682 8 10.5682C9.33333 10.5682 10.6 10.0909 11.6 9.27272C11.8 9.13635 12.0667 9.06817 12.3333 9.20453C14.6667 10.5682 16 12.9545 16 14.3864C16 14.7273 15.7333 15 15.4 15ZM1.33333 13.7045H14.6667C14.3333 12.75 13.4667 11.3864 12.0667 10.4318C10.8667 11.3182 9.46667 11.7273 8 11.7273C6.53333 11.7273 5.13333 11.25 3.93333 10.4318C2.53333 11.4545 1.66667 12.8182 1.33333 13.7045Z" fill="#ADADAD"/>
                        </svg>
                    </div>
                    <template v-if="event.students_groups.groups">
                      <div class="group-list">
                        <template v-if="event.students_groups.students && event.students_groups.students.length">
                          <div class="group-list">
                            <div v-for="(student, key) in event.students_groups.students" class="calendar-info-event__field-text">
                                {{ student.surname }} {{ student.middlename }} {{ student.name }}
                            </div>
                          </div>
                        </template>
                        <div v-for="(group, key) in event.students_groups.groups" class="calendar-info-event__field-text">
                          <div>
                            <v-icon
                              v-if="!isOpend(group.id)"
                              @click.stop="openGroup(group.id)"
                            >mdi-plus</v-icon>
                            <v-icon
                              v-else
                              @click.stop="closeGroup(group.id)"
                            >mdi-minus</v-icon>
                            <span><b>{{ group.name }}</b></span>
                          </div>
                          <div
                            v-if="isOpend(group.id)"
                          >
                            <div class="students-group"
                              v-for="(student, key) in group.students" :key="key"
                            >

                              <div class="students-group-item">{{ student.name }} {{ student.surname }}</div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </template>

                </div>
                <div v-if="event.description" class="calendar-info-event__field">
                    <div class="calendar-info-event__field-icon">
                        <v-icon>description</v-icon>
                    </div>
                    <div  class="calendar-info-event__field-text">
                        {{ event.description }}
                    </div>
                </div>
                <div class="calendar-info-event__field">
                    <div class="calendar-info-event__field-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16" fill="none">
                            <path d="M9.38138 0H0.618491C0.515399 0 0.515399 0 0.412306 0H0.309213C0.309213 0 0.309213 0 0.20612 0.1C0.20612 0.1 0.103027 0.2 0.103027 0.3C0.103027 0.4 0.103027 0.4 0.103027 0.5V13C0.103027 13.1 0.103027 13.1 0.103027 13.2C0.103027 13.2 0.103027 13.2 0.103027 13.3L0.20612 13.4C0.20612 13.4 0.309213 13.5 0.412306 13.5L6.28859 16C6.39169 16 6.49478 16 6.49478 16C6.59787 16 6.70097 16 6.80406 15.9C7.01024 15.8 7.11334 15.6 7.11334 15.4V13.7H9.27829C9.58756 13.7 9.89684 13.4 9.89684 13.1V0.6C9.99994 0.3 9.69066 0 9.38138 0ZM5.87622 14.5L1.23705 12.7V1.5L5.87622 3.3V14.5ZM8.65973 12.5H7.11334V2.9C7.11334 2.6 6.90715 2.4 6.70097 2.3L3.91746 1.2H8.65973V12.5Z" fill="#ADADAD"/>
                        </svg>
                    </div>
                    <div class="calendar-info-event__field-text">
                       {{ event.room.room_group }}
                    </div>
                </div>
                <div class="calendar-info-event__field">
                    <div class="calendar-info-event__field-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M12.0668 12.6667H3.9335C3.60016 12.6667 3.3335 12.4 3.3335 12.0667V3.93334C3.3335 3.6 3.60016 3.33334 3.9335 3.33334H12.0668C12.4002 3.33334 12.6668 3.6 12.6668 3.93334V12.0667C12.6668 12.4 12.4002 12.6667 12.0668 12.6667ZM4.5335 11.4667H11.4002V4.53334H4.5335V11.4667Z" fill="#ADADAD"/>
                            <path d="M0.6 3.93333C0.266667 3.93333 0 3.66667 0 3.33333V0.666667C0 0.266667 0.266667 0 0.6 0H3.46667C3.8 0 4.06667 0.266667 4.06667 0.6C4.06667 0.933333 3.8 1.2 3.46667 1.2H1.2V3.33333C1.2 3.66667 0.933333 3.93333 0.6 3.93333Z" fill="#ADADAD"/>
                            <path d="M3.46667 16H0.6C0.266667 16 0 15.7333 0 15.4V12.7333C0 12.4 0.266667 12.1333 0.6 12.1333C0.933333 12.1333 1.2 12.4 1.2 12.7333V14.8H3.4C3.73333 14.8 4 15.0667 4 15.4C4 15.7333 3.8 16 3.46667 16Z" fill="#ADADAD"/>
                            <path d="M15.3997 4.06667C15.0664 4.06667 14.7997 3.8 14.7997 3.46667V1.2H12.6664C12.3331 1.2 12.0664 0.933333 12.0664 0.6C12.0664 0.266667 12.3331 0 12.6664 0H15.3331C15.7331 0 15.9997 0.266667 15.9997 0.6V3.46667C15.9997 3.8 15.7331 4.06667 15.3997 4.06667Z" fill="#ADADAD"/>
                            <path d="M15.4 16H12.7333C12.4 16 12.1333 15.7333 12.1333 15.4C12.1333 15.0667 12.4 14.8 12.7333 14.8H14.8V12.6C14.8 12.2667 15.0666 12 15.4 12C15.7333 12 16 12.2667 16 12.6V15.4667C16 15.7333 15.7333 16 15.4 16Z" fill="#ADADAD"/>
                        </svg>
                    </div>
                    <div class="calendar-info-event__field-text">
                        {{ event.room.room_number }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      default: () => {},
    },
    event: {
      type: Object,
      default: () => {},
    },
    direction: {
      type: String,
      default: 'left',
    },
  },
  data: () => ({
    configInfoEvent: {
      formatCurrentDate: 'dddd, D MMMM',
      formatStartEndDate: 'HH:mm',
    },
    openGroups: [],
  }),
  computed: {
    getDirectionClass() {
      if (this.direction === 'left') {
        return 'calendar-info-event__direction-left';
      }

      return 'calendar-info-event__direction-right';
    },
    getDateFullDate() {
      return this.getDateByFormat(
        this.event.start_datetime,
        this.configInfoEvent.formatCurrentDate,
      );
    },
    getStartDateByFormat() {
      return this.getDateByFormat(
        this.event.start_datetime,
        this.configInfoEvent.formatStartEndDate,
      );
    },
    getEndDateByFormat() {
      return this.getDateByFormat(this.event.end_datetime, this.configInfoEvent.formatStartEndDate);
    },
  },
  methods: {
    isOpend(id) {
      return this.openGroups.includes(id);
    },
    openGroup(id) {
      if (!this.openGroups.includes(id)) {
        this.openGroups.push(id);
      }
    },
    closeGroup(id) {
      if (this.openGroups.includes(id)) {
        const index = this.openGroups.indexOf(id);
        if (index > -1) {
          this.openGroups.splice(index, 1);
        }
      }
    },
    getDateByFormat(date, format) {
      return this.$moment(date).format(format);
    },
    getTeachers(teachers) {
      let result = '';
      teachers.forEach((item, index) => {
        result += `${index ? ', ' : ''}${item.full_name}`;
      });
      return result;
    },
  },
};

</script>
<style>
  .group-list{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: flex-start;
  }
  .group-list>.group-list{
    padding-left: 23px;
  }
  .calendar-info-event__field-text{
    margin: 0.2rem;
  }
  .v-expansion-panels {
    z-index: 12 !important;
  }
  .students-group-item {
    line-height: 20px;
  }
  .students-group {
    margin-left: 23px;
  }
</style>
