<template>
    <div class="treeview">
        <div class="form-group">
            <v-text-field
                    v-if="searchable"
                    class="treeview__search"
                    rounded
                    flat
                    solo
                    single-line
                    color="#595959"
                    v-model="search"
                    background-color="#F7F7F7"
                    :rules="[required]"
                    prepend-inner-icon="fa-search"
                    :label="$t('message.search') + '...'"
                    :disabled="disabled"
            ></v-text-field>
            <label class="label" v-if="label">
                {{ label }}
                <i class="warning-icon">!</i>
            </label>
        </div>
        <div class="treeview__tree styled-scroll" :class="{'disabled-item': disabled}">
            <v-treeview
                    ref="tree"
                    @input="setSelected"
                    return-object
                    selected-color="#FF8D4C"
                    item-text="full_name"
                    :value="value"
                    item-key="key"
                    :items="items"
                    :item-children="itemChildren"
                    :search="treeSearch"
                    :disabled="disabled"
                    open-on-click
                    multiple-active
                    selectable
                    :filter="filter"
            >
                <template v-slot:label="{ item }">
                    <span :class="[isError(item) ? 'has-error' : '']">
                        {{item[itemLabel] || item.name}}
                    </span>
                </template>
            </v-treeview>
        </div>
    </div>
</template>

<script>
import { debounce, cloneDeep } from 'lodash';

export default {
  name: 'TreeView',
  props: {
    items: {
      type: Array,
      required: true,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    min: {
      type: Number,
      default: () => 0,
    },
    preSelectAll: {
      type: Boolean,
      default: () => false,
    },
    searchable: {
      type: Boolean,
      default: () => true,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    itemChildren: {
      type: String,
      default: () => 'children',
    },
    itemLabel: {
      type: String,
      default: 'name',
    },
    showError: {
      default: () => false,
    },
    errors: {
      default: () => {},
    },
    label: {
      default: () => false,
    },
  },
  computed: {
    required() {
      return !this.showError || this.$t('message.fieldIsRequired');
    },
  },
  watch: {
    search:
      // eslint-disable-next-line
      debounce(function (val) {
        if (val.trim().length > 0 && !this.openAll) {
          this.openAll = true;
          this.$refs.tree.updateAll(true);
        }
        if (val.trim().length < 1) {
          this.openAll = false;
          this.$refs.tree.updateAll(false);
        }
        this.treeSearch = val;
      }, 800),
  },
  mounted() {
    this.setValue();
    if (this.preSelectAll) {
      this.items.forEach((item) => {
        this.value.push(item);
      });
    }
    if (this.min > 0) {
      this.previouseValue = cloneDeep(this.value);
    }
  },
  data: () => ({
    value: [],
    previouseValue: [],
    search: '',
    openAll: false,
    treeSearch: '',
  }),
  methods: {
    setSelected(items) {
      if (items.length >= this.min) {
        this.$emit('onSelect', items);
        this.previouseValue = cloneDeep(items);
      } else if (this.min > 0) {
        this.value = this.previouseValue;
      }
    },
    setValue() {
      this.value.splice(0, this.value.length);
      this.selectedItems.forEach((item) => {
        this.value.push(item);
      });
    },
    isError(item) {
      return this.errors
        ? Object.keys(this.errors).indexOf(String(item.id)) > -1 && !item[this.itemChildren] : null;
    },
    filter(item, search, textKey) {
      if (item[textKey] !== undefined) {
        return item[textKey].toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1;
      }
      return false;
    },

  },
};
</script>

<style lang="scss">
    .treeview{
        position: relative;
        &.setPadding{
            padding-top: 30px;
        }
        .v-treeview-node__label{
            .has-error{
                color: $dangerColor;
                font-weight: 500;
                &:after{
                    content: '';
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: no-repeat center/cover url("../../assets/danger-icon.png");
                    margin-left: 5px;
                }
            }
        }
    }
    .treeview__search{
        color: #595959 !important;
        &.error--text{
            .v-input__slot{
               border-color: $dangerColor !important;
            }
        }
        input, .v-label{
            font-size: 14px;
            padding: 0 !important;
        }
        .v-icon{
            font-size: 16px !important;
        }
        &.v-text-field--rounded {
            .v-input__control{
                height: 50px !important;
                min-height: 40px !important;
                display: block;
                .v-input__slot{
                    border: 1px solid;
                    padding: 0 15px !important;
                }
            }
        }
    }
    .treeview__tree{
        max-height: 320px;
        overflow-y: auto;
    }
</style>
