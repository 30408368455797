<template>
    <div class="recurring-list styled-scroll" v-if="list.length > 0">
        <div class="recurring-list__header">
            <v-row align="center" no-gutters>
                <v-checkbox
                        :disabled="!showDeleteActions"
                        :value="selectAll"
                        hide-details
                        color="#F88D4C"
                        class="shrink mr-2 mt-0"
                        @change="onSelectAll"
                ></v-checkbox>
                <div
                   :class="[
                       'recurring-list__delete',
                       selected.length === 0 || !showDeleteActions
                       ? 'disabled' : ''
                   ]"
                >
                    <img @click="onDelete()" src="../../assets/delete.png" alt="delete"/>
                </div>
                <div v-if="showBackAction"
                     class="recurring-list__back"
                     @click="onBackClick"
                >
                    <v-icon>arrow_back</v-icon>
                </div>
            </v-row>
        </div>
        <div class="recurring-list__body">
            <v-row
                   v-for="event in list"
                   :key="event.id"
                   align="center"
                   :class="[
                   'recurring-list__item',
                   currentId === event.id ? 'active' : '',
                   hasErrors(event),
                   ]"
                   no-gutters
            >
                <v-checkbox
                        :disabled="!showDeleteActions"
                        v-model="selected"
                        :value="event.id"
                        hide-details
                        color="#F88D4C"
                        class="shrink mr-2 mt-0"
                ></v-checkbox>
                <div class="item__text" @click="eventClick(event.id)">
                    {{

                        $moment(event.start_datetime, 'YYYY-MM-DD HH:mm:ss')
                        .format('ddd DD.MM.YYYY HH:mm')
                    }}
                    {{$t('message.inRoom')}}
                    {{event.room.room_number}}
                </div>
            </v-row>
        </div>
    </div>
</template>

<script>

export default {
  name: 'RecurringList',
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    currentId: {
      default: () => null,
    },
    showDeleteActions: {
      type: Boolean,
      default: () => true,
    },
    showBackAction: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    selected: [],
    selectAll: false,
    groupEditing: false,
    eventSelection: [],
  }),
  methods: {
    onSelectAll() {
      this.selectAll = !this.selectAll;
      if (this.selectAll) {
        this.selected = [...this.list.map(item => item.id)];
      } else {
        this.selected = [];
      }
    },
    onDelete() {
      this.$emit('onDelete', this.selected);
      this.selected = [];
      this.selectAll = false;
    },
    eventClick(id) {
      this.$emit('onEventClick', id);
    },
    onBackClick() {
      this.$emit('onBackClick');
    },
    hasErrors(event) {
      if (event.errors) {
        return Object.keys(event.errors).length > 0 ? 'hasError' : '';
      }
      return '';
    },
    flushEditing() {
      this.fieldsToUpdate = { ...this.fieldsToUpdateEmpty };
    },
  },
  watch: {
    selected: {
      handler(newValue) {
        this.eventSelection = newValue;
        this.$emit('onChangeEventSelection', this.eventSelection);

        if (newValue.length > 0) {
          if (this.groupEditing === false) {
            this.groupEditing = true;
          } else {
            this.flushEditing();
          }
        } else {
          this.groupEditing = false;
        }
      },
    },
    groupEditing: {
      handler() {
        this.$emit('onChangeGroupEditing', this.groupEditing);
      },
    },
  },
};
</script>

<style lang="scss">
    .recurring-list {
        padding: 10px 15px;
        border: 1px solid $elementColor;
        border-radius: 4px;
        margin-top: 20px;
        max-height: 250px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .event-form_edit {
        .recurring-list{
            max-height: 320px;
        }
    }

    .recurring-list__header{
        padding-bottom: 5px;
    }

    .recurring-list__item {
        padding-bottom: 5px;
        color: $defaultColor;
        font-weight: 500;
        &.hasError{
            color: $dangerColor;
            .item__text{
                &:after{
                    content: '';
                    display: inline-block;
                    width: 12px;
                    height: 12px;
                    background: no-repeat center/cover url("../../assets/danger-icon.png");
                }
            }
            .v-icon{
                color: $dangerColor
            }
        }
        &.active{
            color: $accentColor;
            font-weight: 500;
        }
        .item__text{
            cursor: pointer;
            position: relative;
            top: 3px;
        }
    }
    .recurring-list__delete {
        margin-right: 10px;
        &.disabled {
            opacity: 0.6;
            pointer-events: none;
            filter: grayscale(100%);
        }
        img{
            position: relative;
            top: 4px;
            cursor: pointer;
        }
    }
    .recurring-list__back{
        i {
            top: 2px;
            position: relative;
            cursor: pointer;
            color: $accentColor !important;
        }
    }
</style>
