var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.list.length > 0)?_c('div',{staticClass:"recurring-list styled-scroll"},[_c('div',{staticClass:"recurring-list__header"},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"disabled":!_vm.showDeleteActions,"value":_vm.selectAll,"hide-details":"","color":"#F88D4C"},on:{"change":_vm.onSelectAll}}),_c('div',{class:[
                   'recurring-list__delete',
                   _vm.selected.length === 0 || !_vm.showDeleteActions
                   ? 'disabled' : ''
               ]},[_c('img',{attrs:{"src":require("../../assets/delete.png"),"alt":"delete"},on:{"click":function($event){return _vm.onDelete()}}})]),(_vm.showBackAction)?_c('div',{staticClass:"recurring-list__back",on:{"click":_vm.onBackClick}},[_c('v-icon',[_vm._v("arrow_back")])],1):_vm._e()],1)],1),_c('div',{staticClass:"recurring-list__body"},_vm._l((_vm.list),function(event){return _c('v-row',{key:event.id,class:[
               'recurring-list__item',
               _vm.currentId === event.id ? 'active' : '',
               _vm.hasErrors(event) ],attrs:{"align":"center","no-gutters":""}},[_c('v-checkbox',{staticClass:"shrink mr-2 mt-0",attrs:{"disabled":!_vm.showDeleteActions,"value":event.id,"hide-details":"","color":"#F88D4C"},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"item__text",on:{"click":function($event){return _vm.eventClick(event.id)}}},[_vm._v(" "+_vm._s(_vm.$moment(event.start_datetime, 'YYYY-MM-DD HH:mm:ss') .format('ddd DD.MM.YYYY HH:mm'))+" "+_vm._s(_vm.$t('message.inRoom'))+" "+_vm._s(event.room.room_number)+" ")])],1)}),1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }