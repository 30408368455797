<template>
   <div>
       <vc-date-picker
               :locale="getLang"
               ref="picker"
               :class="{
                'calendar-picker': true,
                'single': isSingleMode,
                'disabled-item': isAppLoading
               }"
               :mode="mode"
               :firstDayOfWeek="2"
               :value="selectedValue"
               is-inline
               :to-page="goToPage"
               :update-on-input='true'
               @dayclick="changeDate"
       >
           <template slot='header-title' slot-scope='props'>
               <span class="calendar-picker__month">{{props.monthLabel}}</span><br>
               <span class="calendar-picker__year">{{props.year}}</span>
           </template>
       </vc-date-picker>
   </div>
</template>

<script>
// eslint-disable-next-line
window.Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

export default {
  name: 'CalendarPicker',
  props: {
    currentDate: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: 'range',
    },
  },
  data: () => ({
    pickerConfig: {
      addDays: 6,
    },
  }),
  computed: {
    isSingleMode() {
      return this.mode === 'single';
    },
    selectedValue() {
      const date = this.currentDate;

      if (!this.isSingleMode) {
        return {
          start: new Date(date.format()),
          end: new Date(date.format()).addDays(this.pickerConfig.addDays),
        };
      }
      return new Date(date.format());
    },
    goToPage() {
      const date = this.currentDate;
      return {
        month: +this.$moment(date).format('MM'),
        year: +this.$moment(date).format('YYYY'),
      };
    },
  },
  methods: {
    changeDate({ date }) {
      this.$refs.picker.dragValue = null;
      this.$emit('dayClick', date);
    },
  },
};
</script>

<style lang="scss">
    .calendar-picker{
        border: none !important;
        margin-bottom: 20px;
        /*.vc-grid-cell-row-7 .vc-day .vc-opacity-0 {*/
            /*opacity: 0;*/
        /*}*/

        .vc-day .vc-opacity-0 {
            opacity: 0.4;
        }

        .vc-title-wrapper{
            text-align: center;
        }
        .vc-weekday{
            color: $defaultColor
        }
        &__year{
            color: #BFBFBF;
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            top: -10px;
            position: relative;
        }
        .vc-text-sm{
            font-size: 12px;
        }
        .is-today{
            .vc-day-content{
                border: 1px solid $accentColor;
                background-color: $accentColor;
                border-radius: 50%;
                color: #fff !important;
                &:hover{
                    color: $defaultColor !important;
                }
            }
        }
        .vc-highlight{
            background-color: #FEF3E4;
        }
        &.single{
            .vc-highlight{
                border: 1px solid $accentColor;
                background-color: #fff;
            }
            .vc-day-content.vc-text-white{
                color: $defaultColor
            }
        }
        .vc-text-blue-900{
            color: $accentColor
        }
        .vc-day-content{
            &.vc-text-white{
                color: $accentColor;
            }

            &:hover{
                border: 1px solid $accentColor;
                background-color: #fff;
                color: $defaultColor
            }
        }
        .vc-border-blue-700 {
            border-color: $accentColor;
        }
    }
</style>
