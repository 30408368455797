<template>
    <v-dialog
            content-class="conflict-events-modal"
            v-model="isVisibleModal"
            max-width="490"
    >
        <v-card>
            <close-icon class="modal-close" @click.native="hideModal"/>
            <!-- eslint-disable -->
            <v-card-title>
                <attention-icon class="conflict-events-modal__title-icon"/>
                {{$t('message.ohSorry')}}
            </v-card-title>
            <v-card-text>
                <v-container>
                    <div class="conflict-events-modal__list-error">

                      <div
                        class="conflict-events-modal__item-error"
                        v-if="getErrors.emptyStudentGroup"
                      >
                          <div class="conflict-events-modal__title-error flex-center">
                              {{$t('message.studentReservationNoStudentsInGroupError')}}
                              <attention-icon
                                      class="conflict-events-modal__title-error-icon icon-attention__size_small icon-attention__theme_danger"
                              />
                          </div>
                      </div>
                      <div class="conflict-events-modal__item-error"
                           v-if="getErrors.subject_id"
                      >
                        <div class="conflict-events-modal__title-error">
                          {{$t('message.subject')}}
                          <attention-icon
                            class="conflict-events-modal__title-error-icon icon-attention__size_small icon-attention__theme_danger"
                          />
                        </div>
                        <div class="conflict-events-modal__content-error">
                          {{ ...getErrors.subject_id }}
                        </div>
                      </div>
                        <div class="conflict-events-modal__item-error"
                             v-if="getEvents.room"
                        >
                            <div class="conflict-events-modal__title-error">
                                {{$t('message.room')}}
                                <attention-icon
                                        class="conflict-events-modal__title-error-icon icon-attention__size_small icon-attention__theme_danger"
                                />
                            </div>
                            <div class="conflict-events-modal__content-error">
                                {{ $t('message.roomReservationError') }}
                            </div>
                        </div>
                        <div class="conflict-events-modal__item-error"
                             v-if="getEvents.events"
                        >
                            <div class="conflict-events-modal__title-error">
                                {{$t('message.event')}}
                                <attention-icon
                                        class="conflict-events-modal__title-error-icon icon-attention__size_small icon-attention__theme_danger"
                                />
                            </div>
                            <div
                                v-for="(event, key) in getEvents.events"
                                :key="key"
                                class="conflict-events-modal__content-error"
                            >

                              {{
                                  $moment(event.start_datetime, 'YYYY-MM-DD HH:mm:ss')
                                  .format('ddd DD.MM.YYYY HH:mm')
                              }}
                              {{$t('message.inRoom')}}
                              {{event.room_name}}
                            </div>
                        </div>
                        <div
                          class="conflict-events-modal__item-error"
                          v-if="getEvents.students || getErrors.students"
                        >
                            <div class="conflict-events-modal__title-error">
                                {{$t('message.students')}}
                                <attention-icon
                                        class="conflict-events-modal__title-error-icon icon-attention__size_small icon-attention__theme_danger"
                                />
                            </div>
                            <div class="conflict-events-modal__content-error" v-if="getEvents.students">
                                {{ $t('message.studentReservationError') }}
                            </div>
                            <div class="conflict-events-modal__content-error" v-if="getErrors.students">
                                {{ getErrors.students }}
                            </div>
                        </div>
                        <div
                          class="conflict-events-modal__item-error"
                          v-if="getEvents.teachers || getErrors.teachers"
                        >
                            <div class="conflict-events-modal__title-error">
                                {{$t('message.teachers')}}
                                <attention-icon
                                        class="conflict-events-modal__title-error-icon icon-attention__size_small icon-attention__theme_danger"
                                />
                            </div>
                            <div class="conflict-events-modal__content-error" v-if="getEvents.teachers">
                                {{ $t('message.teacherReservationError') }}
                            </div>
                            <div class="conflict-events-modal__content-error" v-if="getErrors.teachers">
                                {{ getErrors.teachers }}
                            </div>
                        </div>
                        <div class="conflict-events-modal__item-error"
                             v-if="isEndDatetime"
                        >
                            <div class="conflict-events-modal__title-error">
                                {{$t('message.date')}}
                                <attention-icon
                                        class="conflict-events-modal__title-error-icon icon-attention__size_small icon-attention__theme_danger"
                                />
                            </div>
                            <div class="conflict-events-modal__content-error">
                                {{ $t('message.lessonShouldEndBefore9Pm') }}
                            </div>
                        </div>
                    </div>
                    <div class="conflict-events-modal__footer">
                        <button-error @click.native="hideModal" class="button__error">
                            {{$t('message.goBackToUpdate')}}
                        </button-error>
                    </div>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

import AttentionIcon from '@icons/AttentionIcon.vue';
import CloseIcon from '@icons/CloseIcon.vue';
import ButtonError from '@buttons/ButtonError.vue';

export default {
  props: {},
  components: {
    AttentionIcon,
    CloseIcon,
    ButtonError,
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      getVisible: 'modalConflictEvents/getVisible',
      getEvents: 'modalConflictEvents/getEvents',
      getErrors: 'modalConflictEvents/getErrors',
      getIsShouldBeCleared: 'modalConflictEvents/getIsShouldBeCleared',
    }),
    isVisibleModal: {
      get() {
        return this.getVisible;
      },
      set() {
        this.hideModal();
      },
    },
    isEndDatetime() {
      return this.getEvents.end_datetime && this.getEvents.end_datetime.length;
    },
  },
  methods: {
    setEvents(events) {
      this.$store.dispatch('modalConflictEvents/setEvents', events);
    },
    setShow(isVisible) {
      this.$store.dispatch('modalConflictEvents/setShow', isVisible);
    },
    clearEvents() {
      this.$store.dispatch('modalConflictEvents/clearEvents');
      this.$store.dispatch('modalConflictEvents/setIsShouldBeCleared', false);
    },
    clearErrors() {
      this.$store.dispatch('modalConflictEvents/clearErrors');
    },
    hideModal() {
      this.setShow(false);
      if (this.getIsShouldBeCleared) {
        this.clearEvents();
        this.clearErrors();
      }
    },
  },
};
</script>

<style lang="scss">
    .v-dialog__content {
        .v-dialog {
            .container {
                min-height: auto;
            }
        }
        .v-card {
            padding: 0;
        }
        .conflict-events-modal {
            .v-card__title {
                text-align: center;
                font-size: 26px;
                line-height: 30px;
                color: $defaultColor;
                margin-bottom: 30px;
                padding: 0;
                padding-top: 40px;
                display: block;
            }
            .conflict-events-modal__title-icon {
                display: block;
                margin: 0 auto 15px;
            }
            .conflict-events-modal__footer {
                display: flex;
                justify-content: center;
            }
            .conflict-events-modal__list-error {
                margin-bottom: 40px;
            }
            .conflict-events-modal__item-error {
                margin-bottom: 35px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .conflict-events-modal__title-error {
                font-size: 18px;
                margin-bottom: 18px;
                color: $defaultColor;
                display: flex;
                align-items: center;
                .conflict-events-modal__title-error-icon {
                    margin-left: 7px;
                }
            }
            .conflict-events-modal__content-error {
                font-size: 14px;
                color: #848484;
            }
            .flex-center{
                justify-content: center;
            }
        }
    }
</style>
