<template>
    <div class="rooms">
        <tree-view
        :disabled="isAppLoading"
        :items="rooms"
        itemChildren="rooms"
        :selectedItems="selectedRooms"
        @onSelect="setSelectedRooms"
        :preSelectAll="false"
        :min="1"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TreeView from '@/components/Shared/TreeView.vue';

export default {
  name: 'Rooms',
  components: {
    TreeView,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    ...mapGetters({
      selectedRooms: 'rooms/getSelectedRooms',
      rooms: 'rooms/getRooms',
    }),
  },
  methods: {
    setSelectedRooms(rooms) {
      this.$store.dispatch('rooms/setSelectedRooms', rooms);
    },
  },
};
</script>

<style lang="scss">
    @media (max-width: 1550px) {
        .rooms {
            .treeview__tree{
                max-height: 200px;
            }
        }
    }
</style>
