<template>
    <div :class="['calendar', disableEvents ? 'disable-events' : '']">
        <CalendarControls
                :startDate="currentDate"
                :settings="getSettingsForCalendarControls"
                @onNextPeriod="nextPeriod"
                @onPrevPeriod="prevPeriod"
        />
        <CalendarView
                :view="view"
                :showEventRoom="showEventRoom"
                :isLoading="isLoading"
                :roomsWithEvents="roomsWithEvents"
                :startDate="currentDate"
                :settings="{showDays: configControlCalendar.showDays}"
                @onCreateEvent="createEvent"
                @onChangeEvent="changeEvent"
                @onDropEvent="onDropEvent"
        />
    </div>
</template>

<script>
import CalendarView from './CalendarView.vue';
import CalendarControls from './CalendarControls.vue';

const settingsForCalendarControls = {
  day: {
    addDay: 1,
    showDays: 1,
  },
  week: {
    addDay: 7,
    showDays: 7,
  },
};

export default {
  components: {
    CalendarView,
    CalendarControls,
  },
  props: {
    currentDate: {
      type: Object,
    },
    disableEvents: {
      type: Boolean,
      default: () => false,
    },
    disableCreate: {
      type: Boolean,
      default: () => false,
    },
    disableUpdate: {
      type: Boolean,
      default: () => false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    showPeriod: {
      type: Boolean,
      default: () => true,
    },
    showEventRoom: {
      type: Boolean,
      default: () => false,
    },
    roomsWithEvents: {
      type: Array,
      default: () => [],
    },
    selectedRooms: {
      type: Array,
      default: () => [],
    },
    view: {
      type: String,
      default: 'week',
    },
  },
  data: () => ({
    configControlCalendar: {
      addDay: 7,
      showDays: 7,
    },
    configControl: {
      formatDate: 'DD MMM YYYY',
    },
    editableEvent: {},
  }),
  computed: {
    getSettingsForCalendarControls() {
      return settingsForCalendarControls[this.view];
    },
    modal: {
      get() {
        return this.modalShow;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  watch: {
    currentDate() {
      this.$emit('getData');
    },
    selectedRooms() {
      this.$emit('getData');
    },
  },
  methods: {
    createEvent({ day, roomId }) {
      if (this.disableEvents || this.disableCreate) {
        return;
      }
      this.$emit('createEvent', { day, roomId });
    },
    changeEvent(event) {
      const { id } = this.$store.getters['auth/getUser'];
      if (this.disableUpdate && event.created_by !== id) {
        return;
      }
      this.$emit('changeEvent', event.id);
    },
    onDropEvent(data) {
      const { id } = this.$store.getters['auth/getUser'];
      if (this.disableUpdate && data.created_by !== id) {
        return;
      }
      this.$emit('onDropEvent', data);
    },
    updateData() {
      this.$emit('getData');
    },
    nextPeriod(period) {
      this.$emit('nextPeriod', period);
    },
    prevPeriod(period) {
      this.$emit('prevPeriod', period);
    },
  },
};
</script>

<style lang="scss">
    .calendar__controls{
        background-color: #F9F9F9;
        padding: 15px 40px;
        display: flex;
        align-items: center;
    }
    .calendar__text-start-end-date{
        font-weight: 800;
        font-size: 18px;
        line-height: 21px;
        color: $defaultColor;
    }
    .calendar__controls-change-period{
        color: $elementColor;
        display: flex;
        border-radius: 4px;
        width: 80px;
        height: 40px;
        border: $defaultBorder;
        background-color: #fff;
        margin-left: 20px;
        box-shadow: 2px 2px 5px rgba(142, 141, 187, 0.25);
    }
    .calendar__button-prev,
    .calendar__button-next{
        width: 50%;
        height: 100%;
    }
    .calendar__button-next{
        border-left: $defaultBorder;
    }
    .calendar{
        &.static-header{
            .calendar__row_header{
                position: static;
            }
        }
        background-color: #FDFDFD;
        width: 100%;
        overflow: auto;
        overflow-y: hidden;
        &.disable-events{
            .calendar__cell{
                cursor: auto;
                background-color: #fff !important;
            }
        }
    }
    .calendar__body{
        padding-top: 0;
        position: relative;
        overflow-y: auto;
        height: calc(100vh - 158px);
    }
    .calendar__body.calendar_day{
        overflow-y: hidden;
    }
    .calendar__body_day{
        height: 100%;
    }
    .v-expansion-panels{
        z-index: 11;
    }
    /*.calendar__body-content{*/
        /*overflow: auto;*/
    /*}*/
    .calendar__content{
        position: relative;
    }
    .calendar__row{
        display: flex;
        box-sizing: border-box;
        width: 100%;
        border-bottom: $defaultBorder;
    }
    .calendar__row_header{
        padding-left: 55px;
        padding-bottom: 10px;
        min-height: 58px;
        display: flex;
        position: sticky;
        left: 0;
        top:0;
        background-color: #fff;
        z-index: 20;
        .calendar__header{
            margin-top: auto;
        }
    }
    .calendar__header{
        text-align: center;
    }
    .calendar__title{
        font-weight: bold;
        margin-bottom: 2px;
        font-size: 14px;
        color: $calendarFontDefaultColor;
        &:before{
            margin: 0 auto 2px;
            display: block;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 4px 0 4px;
            border-color: transparent transparent transparent transparent;
        }
    }
    .calendar__date{
        font-size: 12px;
        color: $calendarFontSecondColor;
    }
    .calendar__header_current .calendar__title,
    .calendar__header_current .calendar__date{
        color: $accentColor;
    }
    .calendar__header_current .calendar__title:before{
        border-color: $accentColor transparent transparent transparent;
    }
    .calendar__times{
        min-width: 55px;
        border-right: $defaultBorder;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $calendarFontTertiaryColor;
        font-size: 12px;
    }
    .calendar__cell{
        border-right: $defaultBorder;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
        cursor: pointer;
        &:hover{
            background-color: #f6f6f6;
        }
    }
    .calendar__event-wrapper{
        position: absolute;
        top: 0;
        left: 0;
        padding: 1px;
        z-index: 2;
        transition: z-index 0.5s;
        cursor: pointer;
        &.active{
            .calendar-info-event-wrapper{
                display: none;
            }
        }
    }
    .calendar__event-wrapper:hover{
        z-index: 3;
        transition: z-index 0.5s;
    }
    .calendar__events{
        display: flex;
        height: 100%;
        position: absolute;
        width: calc(100% - 55px);
        top: 0;
        left: 0;
        margin-left: 55px;
        &.pointer-events_none{
            .calendar__event-wrapper:not(.active){
                pointer-events: none;
            }
        }
    }
    .calendar__events_day{
        width: calc(100% - 170px);
        margin-left: 170px;
        .calendar__event{
            padding: 6px;
        }
    }
    .calendar__event{
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: 12px;
        border-radius: 3px;
        background-image: repeating-linear-gradient(
                        -45deg, rgba(255, 255, 255, 0.06),
                        rgba(255, 255, 255, 0.06) 20px,
                        #ffffff00 20px, #ffffff00 40px
        );
    }
    .calendar__event-title{
        color: #fff;
        font-size: 14px;
        line-height: 16px;
        font-weight: 800;
        pointer-events: none;
    }
    .calendar-info-event-wrapper{
        position: fixed;
        z-index: 101;
        max-height: 310px;
        // overflow-x: hidden;
        // overflow-y: auto;
    }
    .calendar-info-event{
        position: relative;
        width: 230px;
        color: black;
        padding: 22px 16px 20px;
        background-color: #fff;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.14);
        border-radius: 7px;
    }
    .calendar-info-event__direction-left .calendar-info-event.arrow:before{
        top: 50%;
        margin-top: -15px;
        left: 0;
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: 10px solid black;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        transform: rotate(45deg);
        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.10);
    }
    .calendar-info-event__direction-right .calendar-info-event.arrow:before{
        top: 50%;
        margin-top: 14px;
        right: -20px;
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border: 10px solid black;
        border-color: transparent transparent #fff #fff;
        transform-origin: 0 0;
        transform: rotate(-135deg);
        box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.10);
    }
    .calendar-info-event__direction-left{
        padding-left: 15px;
    }
    .calendar-info-event__direction-right{
        padding-right: 15px;
    }
    /*.calendar-info-event-fade-enter-active, .calendar-info-event-fade-leave-active {*/
        /*transition: opacity .4s;*/
    /*}*/
    .calendar-info-event-fade-enter, .calendar-info-event-fade-leave-to{
        opacity: 0;
    }
    .calendar-info-event__times{
        margin-bottom: 20px;
        display: flex;
        position: relative;
    }
    .calendar-info-event__field{
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .calendar-info-event__field-text{
        color: $calendarFontDefaultColor;
        font-size: 14px;
        line-height: 16px;
    }
    .calendar-info-event__field-icon,
    .calendar-info-event__times-icon{
        margin-right: 15px;
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            fill: #adadad;
        }
    }
    .calendar-info-event__times-icon{
        position: relative;
        top: 2px;
    }
    .calendar-info-event__times-duration{
        background-color: #FF8D4C;
        padding: 4px 10px;
        color: #fff;
        font-size: 12px;
        display: inline-block;
        border-radius: 15px;
        position: absolute;
        right: 0;
        top: -2px;
    }
    .calendar-info-event__times-start-end{
        font-size: 14px;
        color: $calendarFontDefaultColor;
    }
    .calendar-info-event__times-current{
        color: #adadad;
        font-size: 11px;
        &:first-letter{
            text-transform: uppercase;
        }
    }
    .calendar__room-group{
        background-color: #F7F7F7;
    }
    .calendar__room-group-title{
        min-height: 30px !important;
        color: $calendarFontDefaultColor;
        font-size: 12px;
        background-color: #F7F7F7;
        padding: 0 25px;
    }
    .calendar__room-group-title .v-expansion-panel-header__icon{
        margin-left: 0;
    }
    .calendar__room-group-title .v-icon{
        color: $primaryColor !important;
    }
    .calendar__room-group-content .v-expansion-panel-content__wrap{
        padding: 0;
    }
    .calendar__room-group:after{
        display: none;
    }
    .calendar__room-row{
        display: flex;
        border-bottom: $defaultBorder;
        position: relative;
    }
    .calendar__room-cell{
        border-left: $defaultBorder;
        position: relative;
        z-index: 1;
        &:not(.calendar__room-cell_name){
            cursor: pointer;
            &:hover{
                background-color: #f6f6f6;
            }
        }
    }
    .calendar__room-drop{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .calendar__room-cell_name,
    .calendar__header-title{
        min-width: 170px;
        display: flex;
        align-items: center;
        padding-left: 25px;
        font-size: 12px;
        color: $calendarFontTertiaryColor;
        border-left: 0;
    }
    .calendar_theme-second{
        .calendar__date{
            color: $calendarFontDefaultColor;
        }
        .calendar__row_header{
            padding-left: 0;
        }
    }

    .calendar_week_content{
      overflow: auto;
      height: calc(100vh - 160px);
    }

    // frozen sidebar
    .fixed_sidebar_day{
      display: flex;
      .calendar__events{
          margin-left: 5px !important;
      }
      .calendar__events_day{
          margin-left: 5px !important;
      }
      .calendar{
        &.static-header{
            .calendar__row_header{
                position: unset !important;
            }
        }
      }
      .fixed_sidebar{

        z-index: 12;
        width: 170px;
        min-width: 170px;
        padding-bottom:25px;

        .calendar__content_scroll{
            position: absolute;
            top: 0;
        }
      }
      .fixed_sidebar_calendar_content{
        overflow: auto;
      }
    }
</style>
