<template>
    <div>
        <CalendarViewWeek
                v-if="view === 'week'"
                :showEventRoom="showEventRoom"
                :settings="settings"
                :startDate="startDate"
                :events="getEventsRooms"
                :isLoading="isLoading"
                @onCreateEvent="createEvent"
                @onChangeEvent="changeEvent"
                @onDropEvent="onDropEvent"
        />
        <CalendarViewDay
                v-if="view === 'day'"
                :roomsWithEvents="getEventsRooms"
                :showEventRoom="showEventRoom"
                :settings="settings"
                :startDate="startDate"
                :isLoading="isLoading"
                @onCreateEvent="createEvent"
                @onChangeEvent="changeEvent"
                @onDropEvent="onDropEvent"
        />
    </div>
</template>

<script>
import CalendarViewWeek from '@components/Calendar/CalendarViewWeek.vue';
import CalendarViewDay from '@components/Calendar/CalendarViewDay.vue';
import constants from '@/constants';

export default {
  components: {
    CalendarViewWeek,
    CalendarViewDay,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    showEventRoom: {
      type: Boolean,
      default: () => false,
    },
    settings: {
      type: Object,
      default: () => {},
    },
    startDate: {
      type: Object,
    },
    roomsWithEvents: {
      type: Array,
    },
    view: {
      type: String,
      default: 'week',
    },
  },
  data: () => ({}),
  created() {},
  computed: {
    getEventsRooms() {
      const events = {
        [constants.calendarViews.week]: this.roomsWithEvents.length
          ? this.roomsWithEvents[0].room_reservations : [],
        [constants.calendarViews.day]: this.roomsWithEvents,
      };
      return events[this.view];
    },
  },
  methods: {
    createEvent({ day, roomId }) {
      this.$emit('onCreateEvent', { day, roomId });
    },
    changeEvent(event) {
      this.$emit('onChangeEvent', event);
    },
    onDropEvent(data) {
      this.$emit('onDropEvent', data);
    },
  },
  filters: {},
};
</script>
