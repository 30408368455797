<template>
    <div class="custom-multiselect">
        <div class="form-group">
            <v-combobox v-if="!disabled"
                id="teachers"
                ref="teachers"
                :items="items"
                return-object
                @change="input"
                :item-text="itemText"
                :item-value="itemValue"
                :rules="[required]"
                outlined
                clearable
                :placeholder="$t('message.select')"
            >
            </v-combobox>
            <label
              :class="['label', hasAnyError() ? 'has-error' : '']"
              v-if="label"
            >
              {{ label }}
            </label>
        </div>
        <div class="selections-list" v-show="value.length > 0">
            <div v-for="(item, index) in value"
                 :key="item.id || index"
                 class="selections-list__item"
            >
                <!--TODO will Complete-->
                <span>
                  {{ item[itemText] }}
                  <i class="fas fa-times" @click="remove(item[itemValue])" v-if="!disabled"></i>
                  <v-tooltip
                    v-if="isError(item.id)"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                          <i class="warning-icon"
                            v-bind="attrs"
                            v-on="on"
                          >!</i>
                    </template>
                    <span>{{ teacherError(item.id) }}</span>
                  </v-tooltip>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CustomMultiSelect',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: () => 'name',
    },
    itemActiveKey: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: () => 'id',
    },
    errors: {
      type: [Array, Object],
      default: () => [],
    },
    label: {},
    showError: {},
    disabled: {
      default: () => false,
    },
  },
  computed: {
    required() {
      return !this.showError || this.$t('message.fieldIsRequired');
    },
  },
  data: () => ({

  }),
  methods: {
    input(val) {
      if (val && val.id) {
        this.$emit('onInput', val);
      }
    },
    isError(id) {
      return this.errors ? Object.keys(this.errors).indexOf(String(id)) > -1 : null;
    },
    teacherError(id) {
      if (this.errors !== undefined && this.errors !== null) {
        if (this.errors[id] !== undefined) {
          return this.$moment(this.errors[id].reservation[0].start_datetime, 'YYYY-MM-DD HH:mm:ss')
            .format('ddd DD.MM.YYYY HH:mm');
        }
      }
      return '';
    },
    hasAnyError() {
      return this.errors ? Object.keys(this.errors).length > 0 : null;
    },
    remove(val) {
      if (val && !this.disabled) {
        this.$emit('onRemove', val);
      }
    },
  },
};
</script>

<style scoped lang="scss">

    .label.has-error{
        color: $dangerColor;
        font-weight: 500;
        &:after{
            content: '';
            display: inline-block;
            width: 11px;
            height: 11px;
            background: no-repeat center/cover url("../../assets/danger-icon.png");
            margin-left: 5px;
        }
    }
    .selections-list{
        padding: 10px;
        border: 1px solid $elementColor;
        border-radius: 5px;
        margin-top: -10px;
    }
    .selections-list__item{
        color: $defaultColor;
        margin-bottom: 5px;
        .disabled{
          color: gray;
        }
        &.has-error{
            color: $dangerColor;
            font-weight: 500;
            &:after{
                content: '';
                display: inline-block;
                width: 11px;
                height: 11px;
                background: no-repeat center/cover url("../../assets/danger-icon.png");
                margin-left: 5px;
            }
        }
        i{
            color: $primaryColor;
            margin-left: 7px;
            cursor: pointer;
        }
    }
</style>
