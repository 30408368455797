<template>
    <v-form v-model="formValidGlobal" ref="form"
            :class="['event-form', getIsEdit ? 'event-form_edit' : '']">
        <v-row>
            <v-col cols="12" sm="6" md="4">
                <div class="form-group">
                    <v-select
                            :disabled="groupEditing"
                            :items="getCategories"
                            v-model="form.category_id"
                            item-text="name"
                            item-value="id"
                            id="category"
                            @change="onCategoryChange"
                            :rules="[_rules.selectRequired]"
                            outlined
                            :placeholder="$t('message.select')"
                    ></v-select>
                    <label class="label" for="category">
                        {{$t('message.category')}}
                        <i class="warning-icon">!</i>
                    </label>
                </div>
                <div v-if="!getIsLesson" class="form-group">
                    <v-text-field
                            id="name"
                            v-model="form.event_name"
                            outlined
                            :placeholder="$t('message.eventName')"
                            :rules="[_rules.required]"
                    ></v-text-field>
                    <label class="label" for="name">
                        {{$t('message.name')}}
                        <i class="warning-icon">!</i>
                    </label>
                </div>
                <div class="form-group form-group_radio"
                    v-if="(!isStudent()&&!isTeacher()) || isAdministrator()"
                    @click="relaseField('status')"
                >
                    <label class="label" for="statuses">
                        {{$t('message.status')}}
                    </label>
                    <v-radio-group
                        :disabled="isDisabledField('status')"
                        id="statuses"
                        v-model="form.status_id"
                        :mandatory="false"
                    >
                        <v-radio v-for="{id, color, name} in getStatuses"
                                 :key="id"
                                 :color="color"
                                 :label="name"
                                 :value="id"
                                 @change="fieldsToUpdate.status = true"
                        >
                        </v-radio>
                    </v-radio-group>
                </div>
                <div
                    v-if="getIsLesson"
                    class="form-group"
                    @click="relaseField('event')"
                >
                    <v-autocomplete
                            :disabled="isDisabledField('event')"
                            id="event"
                            :items="getSubjects"
                            v-model="form.subject_id"
                            :rules="[_rules.selectRequired]"
                            item-text="name"
                            item-value="id"
                            outlined
                            :placeholder="$t('message.select')"
                    ></v-autocomplete>
                    <label class="label" for="event">
                        {{$t('message.event')}}
                        <i class="warning-icon">!</i>
                    </label>
                </div>
                <div v-if="!getIsLesson" class="form-group">
                    <v-textarea
                            outlined
                            v-model="form.description"
                            name="input-7-4"
                            :placeholder="$t('message.eventDescription')"
                            no-resize
                    ></v-textarea>
                    <label class="label" for="event">
                        {{$t('message.description')}}
                        <i class="warning-icon">!</i>
                    </label>
                </div>
                <div
                    :class="['form-group', formErrors.room ? 'has-error' : '']"
                    @click="relaseField('location')"
                >
                    <v-autocomplete
                            :disabled="isDisabledField('location')"
                            id="location"
                            :items="getLocations"
                            v-model="form.room_id"
                            :rules="[_rules.selectRequired]"
                            item-text="name"
                            item-value="id"
                            outlined
                            :placeholder="$t('message.select')"
                    ></v-autocomplete>
                    <label class="label" for="location">
                        {{$t('message.location')}}
                        <i class="warning-icon">!</i>
                    </label>
                </div>
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <div class="form-group">
                    <label class="label" for="period">
                        {{$t('message.period')}}
                        <i class="warning-icon">!</i>
                    </label>
                    <v-select
                            :disabled="disablePeriod"
                            :items="periods"
                            v-model="isSingleEvent"
                            id="period"
                            outlined
                            :placeholder="$t('message.select')"
                    ></v-select>
                </div>
                <div class="form-date-group single-event" v-if="isSingleEvent">
                    <div class="form-group form-date-group__item form-group_date form-single-date">
                        <DatePicker
                                :disabled="groupEditing"
                                :label="$t('message.date')"
                                :placeholder="$t('message.select')"
                                v-model="form.start_date"
                                :textFieldVal="dateTextFieldValue"
                                :rules="[_rules.requiredSingleEvent]"
                                customId="single-date"
                        />
                    </div>
                    <div class="form-group form-date-group__item form-time form-time_from"
                        @click="relaseField('time_from')"
                    >
                        <TimePicker
                                :disabled="isDisabledField('time_from')"
                                menuRef="menuFrom"
                                v-model="form.start_time"
                                :textFieldVal="form.start_time"
                                :rules="[
                                    _rules.requiredSingleEvent,
                                    _rules.time,
                                    _rules.minTime,
                                    _rules.maxTime,
                                ]"
                                :label="$t('message.from')"
                                customId="time-from"
                                placeholder="10:00"
                        />
                    </div>
                    <div
                        class="form-group form-date-group__item form-time form-time_to"
                        @click="relaseField('time_to')"
                    >
                        <TimePicker
                                :disabled="isDisabledField('time_to')"
                                menuRef="menuTo"
                                v-model="form.end_time"
                                :textFieldVal="form.end_time"
                                :rules="[
                                    _rules.requiredSingleEvent,
                                    _rules.time,
                                    _rules.minEndTime,
                                    _rules.maxTime,
                                ]"
                                :label="$t('message.to')"
                                customId="time-to"
                                placeholder="21:00"
                        />
                    </div>
                </div>
                <div class="recurring-event" v-else>
                    <div class="recurring-event__range">
                        <v-row>
                            <v-col cols="6">
                                <div class="form-group form-group_date">
                                    <DatePicker
                                            :label="$t('message.dateFrom')"
                                            :placeholder="$t('message.select')"
                                            :rules="[_rules.requiredRecurringEvent]"
                                            v-model="recurringFormData.start_date"
                                            :textFieldVal="
                                                dateToVisibleFormat(recurringFormData.start_date)
                                            "
                                            customId="start-date"
                                    />
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <div class="form-group form-group_date">
                                    <DatePicker
                                            :disabled="groupEditing"
                                            :label="$t('message.dateUntil')"
                                            :placeholder="$t('message.select')"
                                            :rules="[_rules.requiredRecurringEvent]"
                                            v-model="recurringFormData.end_date"
                                            :textFieldVal="
                                                dateToVisibleFormat(recurringFormData.end_date)
                                            "
                                            :min="recurringEndDateMin"
                                            customId="end-date"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="recurring-event__time">
                        <v-row>
                            <v-col cols="6">
                                <div class="form-group">
                                    <TimePicker
                                            menuRef="menuFromRec"
                                            v-model="form.start_time"
                                            :textFieldVal="form.start_time"
                                            :rules="[
                                                _rules.requiredRecurringEvent,
                                                _rules.time,
                                                _rules.minTime,
                                                _rules.maxTime,
                                            ]"
                                            :label="$t('message.timeFrom')"
                                            customId="time-from"
                                            placeholder="10:00"
                                    />
                                </div>
                            </v-col>
                            <v-col cols="6">
                                <div class="form-group">
                                    <TimePicker
                                            menuRef="menuToRec"
                                            v-model="form.end_time"
                                            :textFieldVal="form.end_time"
                                            :rules="[
                                                _rules.requiredRecurringEvent,
                                                _rules.time,
                                                _rules.minEndTime,
                                                _rules.maxTime,
                                            ]"
                                            :label="$t('message.timeTo')"
                                            customId="time-to"
                                            placeholder="21:00"
                                    />
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <div class="recurring-event__interval">
                        <Interval
                          ref="interval"
                          @onDaysChange="(days) => recurringFormData.week_days = [...days]"
                          @onIntervalChange="setInterval"
                        />
                    </div>
                    <div :class="['recurring-event__add', isValidRecurring ? '' : 'disabled']">
                        <span @click="createRecurringEvents()">{{$t('message.add')}}</span>
                    </div>
                </div>
                <RecurringList
                        :list="getRecurringEvents"
                        :currentId="form.id"
                        :showBackAction="canBack && getIsRecurringOnCreating"
                        @onDelete="deleteDrafts"
                        @onEventClick="changeEditableEvent"
                        @onBackClick="backToRecurring"
                        @onChangeGroupEditing="onChangeGroupEditing"
                        @onChangeEventSelection="onChangeEventSelection"
                />
            </v-col>
            <v-col cols="12" sm="6" md="4">
                <div
                    class="form__students"
                    @click="relaseField('students')"
                >
                    <students
                            ref="students"
                            :showError="showStudentsError"
                            :errors="formErrors.students"
                            :label="$t('message.students')"
                            itemLabel="full_name"
                            @onSelect="selectStudents"
                            :disabled="
                                (isStudent() || isTeacher())
                                && !isAdministrator() || isDisabledField('students')
                            "
                            :students="filteredStudents"
                            :selectedStudents="selectedStudentsComputed"
                    />
                </div>
                <div class="form__teachers">
                    <div
                        class="form-group"
                        @click="relaseField('teachers')"
                    >
                        <CustomMultiSelect v-show="filtredTeachers.length"
                            :disabled="isDisabledField('teachers') || teachersDisabled"
                            v-model="form.teachers"
                            :items="filtredTeachers"
                            @onInput="teachersChanged"
                            @onRemove="teacherRemove"
                            item-text="full_name"
                            custom-item-text="active"
                            item-active-key="status"
                            item-value="id"
                            :errors="formErrors.teachers"
                            :label="$t('message.teachers')"
                            :showError="showTeachersError"
                        />
                    </div>
                </div>
            </v-col>
        </v-row>
        <div class="form-control">
            <div class="form-control__left">
                <button class="m-button" @click.prevent="cancel({ shouldConfirm: true })">
                    {{$t('message.cancel')}}
                </button>
                <button class="m-button m-button_danger"
                        v-if="getIsEdit && !getIsRecurringOnCreating"
                        :disabled="!canDelete"
                        @click="deleteEvent">
                    {{$t('message.delete')}}
                </button>
            </div>
            <div class="form-control__right">
                <button class="m-button"
                    v-if="(isSingleEvent || !isSingleEvent) && this.groupEditing > 0"
                    @click.stop.prevent="save"
                >
                    {{isSingleEvent ? $t('message.save') : $t('message.saveAll')}}
                </button>

                <button class="m-button m-button_success"
                    v-if="isSingleEvent ||
                      (!isSingleEvent && getRecurringEvents.length > 0 && this.groupEditing <= 0)"
                    @click="submit"
                    :disabled="isSingleEvent ? false : isRecurringListErrors"
                >
                    {{isSingleEvent ? $t('message.saveClose') : $t('message.saveAll')}}
                </button>
            </div>
        </div>
    </v-form>
</template>

<script>

import { mapGetters } from 'vuex';
import constants from '@/constants';

// Components
import Students from '@/components/Students/StudentsTree.vue';
import CustomMultiSelect from '@/components/Shared/CustomMultiSelect.vue';
import DatePicker from '@/components/Shared/DatePicker.vue';
import TimePicker from '@/components/Shared/TimePicker.vue';
import Interval from '@/components/Shared/Interval.vue';
import RecurringList from '@/components/RecurringEventsList/RecurringList.vue';

// Mixins
import rulesMixin from '@/mixins/Form/rules';
import recurringListMixin from './mixins/recurringList';
import studentsMixin from './mixins/students';
import teachersMixin from './mixins/teachers';
import recurringCreationMixin from './mixins/recurringCreation';

export default {
  name: 'EventForm',
  props: {
    date: {},
    roomId: {
      type: null,
      default: () => '',
    },
    formStartData: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.setFormData(this.date, this.roomId);
  },
  mounted() {
    console.log('typeof this.form.category_id');
    console.log(typeof this.form.category_id);
    console.log('mounted');
    if (this.getIsEdit) {
      console.log('if (this.getIsEdit) {');
      this.getEventsByMeta(this.form.event_meta.id);
    }
    if (this.isTeacher() && !this.isAdministrator()) {
      console.log('if (this.isTeacher() && !this.isAdministrator()) {');
      this.teachersChanged(this.filtredTeachers[0]);
      this.teachersDisabled = true;
    }
    if (typeof this.form.category_id !== 'number'
      && (!this.isAdministrator() && !this.isAttendant())
      && (this.isTeacher() || this.isStudent())) {
      console.log('if (this.isTeacher() || this.isStudent()) {');
      this.form.category_id = 2;
    }
    if (typeof this.form.category_id !== 'number' && (this.isAdministrator() || this.isAttendant())) {
      console.log('if (typeof this.form.category_id');
      this.form.category_id = 1;
    }
    this.onCategoryChange();
  },
  components: {
    RecurringList,
    Interval,
    TimePicker,
    DatePicker,
    Students,
    CustomMultiSelect,
  },
  mixins: [
    rulesMixin,
    recurringCreationMixin,
    teachersMixin,
    studentsMixin,
    recurringListMixin,
  ],
  data: () => ({
    groupEditing: false,
    eventSelection: [],
    periods: [
      {
        text: window.app.$t('message.single'),
        value: true,
      },
      {
        text: window.app.$t('message.recurring'),
        value: false,
      },
    ],
    form: {
      start_date: '',
      event_name: '',
      description: '',
      category_id: '',
      status_id: '',
      subject_id: '',
      room_id: '',
      teachers: [],
      students: [],
      start_time: '',
      end_time: '',
      created_by: null,
    },
    isSingleEvent: true,
    disablePeriod: true,
    teachersDisabled: false,
    doNotClose: false,
    fieldsToUpdate: {
    },
    fieldsToUpdateEmpty: {
      status: false,
      event: false,
      location: false,
      time_from: false,
      time_to: false,
      students: false,
      teachers: false,
    },
  }),
  computed: {
    ...mapGetters({
      getSubjects: 'subjects/getSubjects',
      getRooms: 'rooms/getRooms',
      getEvents: 'modalConflictEvents/getEvents',
      getErrors: 'modalConflictEvents/getErrors',
      getIsFormChanged: 'eventForm/getIsFormChanged',
      getStatuses: 'statuses/getStatuses',
    }),

    selectedStudentsComputed() {
      if (this.isAdministrator()) {
        return this.form.students;
      }
      if (this.isAttendant()) {
        return this.form.students;
      }
      if (this.isStudent()) {
        return [this.$store.getters['auth/getUser']];
      }

      return [];
    },

    hasStudents() {
      return this.form.students.length;
    },
    hasTeachers() {
      return this.form.teachers.length;
    },
    getCategories() {
      let categories = this.$store.getters['categories/getCategories'];

      if (!this._$can('c', 'event_reservation')) {
        categories = categories.filter(({ id }) => id !== 2);
      }

      if (!this._$can('c', 'event_meta')) {
        categories = categories.filter(({ id }) => id !== 1);
      }

      if (!this._$can('c', 'event')) {
        categories = categories.filter(({ id }) => id === 2);
      }

      return categories;
    },
    formErrors() {
      return this.getEvents || {};
    },
    getIsEdit() {
      return !!this.form.id;
    },
    getIsLesson() {
      return this.form.category_id === 1;
    },
    canDelete() {
      const { id } = this.$store.getters['auth/getUser'];
      if (id === this.form.created_by) {
        return true;
      }
      return this._$can('d', 'event');
    },
    dateTextFieldValue() {
      return this.form.start_date
        ? this.$moment(this.form.start_date, constants.backendDateFormat).format('DD.MM.YYYY')
        : null;
    },
    getLocations() {
      let locations = [];
      this.getRooms.forEach((group) => {
        locations = [...locations, ...group.rooms];
      });
      return locations;
    },
  },
  watch: {
    getSelectedStudents: {
      handler() {
        this.showStudentsError = false;
      },
    },
    'recurringFormData.start_date': {
      handler() {
        const date = this.$moment(this.recurringFormData.start_date, constants.backendDateFormat);
        if
        (date.isAfter(this.$moment(this.recurringFormData.end_date, constants.backendDateFormat))) {
          this.recurringFormData.end_date = date.add(6, 'days').format(constants.backendDateFormat);
        }
      },
    },
    form: {
      handler() {
        if (!this.getIsFormChanged) {
          this.$store.dispatch('eventForm/setFormChanged', true);
        }
      },
      deep: true,
    },
  },
  methods: {
    isDisabledField(fieldName) {
      return this.groupEditing && !this.fieldsToUpdate[fieldName];
    },
    relaseField(fieldName) {
      this.fieldsToUpdate[fieldName] = true;
    },
    setFormData(date, roomId) {
      // const currentUser = this.$store.getters['auth/getUser'];
      const form = JSON.parse(JSON.stringify({
        ...this.formStartData,
        start_date: date
          ? this.$moment(date).format(constants.backendDateFormat)
          : this.formStartData.start_date,
        start_time: date
          ? this.$moment(date).format(constants.timeFormat)
          : this.formStartData.start_time,
      }));
      this.form = {
        ...form,
        room_id: form.id ? form.room_id : roomId,
      };
      this.recurringFormData.start_date = form.start_date;
      setTimeout(() => {
        this.$store.dispatch('eventForm/setFormChanged', false);
      }, 200);
    },
    flushEditing() {
      this.fieldsToUpdate = { ...this.fieldsToUpdateEmpty };
    },
    save(e) {
      this.doNotClose = true;
      this.submit(e);
      this.doNotClose = false;
      this.flushEditing();
    },
    submit(e) {
      e.preventDefault();
      if (this.isFormValid()) {
        const eventsData = {
          form: {
            ...this.form,
            fields: this.fieldsToUpdate,
            event_ids: this.eventSelection,
            start_datetime: `${this.form.start_date} ${this.form.start_time}:00`,
            end_datetime: `${this.form.start_date} ${this.form.end_time}:00`,
            students: [...this.form.students.map(item => item.id)],
            teachers: this.filtredTeachers.length > 1
              ? [...this.form.teachers.map(item => item.id)]
              : [...this.filtredTeachers.map(item => item.id)],
          },
          event_meta: this.getRecurringEvents[0] ? this.getRecurringEvents[0].event_meta.id : null,
          closeForm: (this.doNotClose)
            ? false : this.isSingleEvent && !this.getIsRecurringOnCreating,
        };
        this.$emit(this.isSingleEvent ? 'onSubmit' : 'onSubmitRecurring', eventsData);
      }
    },
    cancel({ shouldConfirm, update }) {
      this.$emit('onCancel', { shouldConfirm, update });
    },
    isFormValid() {
      // if (this.studentsIsSelected || this.teachersIsSelected) {
      //   this.showStudentsError = true;
      //   this.showTeachersError = true;
      // }
      if (!this.studentsIsSelected && !this.teachersIsSelected) {
        this.showStudentsError = true;
        this.showTeachersError = true;
      } else {
        this.showStudentsError = false;
        this.showTeachersError = false;
      }
      return this.formValidate(this.$refs.form)
                    && (this.studentsIsSelected || this.teachersIsSelected);
    },
    deleteEvent(e) {
      e.preventDefault();
      const { id } = this.form;
      this.$emit('onDelete', id);
    },
    dateToVisibleFormat(date) {
      return date
        ? this.$moment(date, constants.backendDateFormat).format('DD.MM.YYYY')
        : null;
    },
    setInterval(interval) {
      this.recurringFormData.interval = interval;
    },
    onChangeGroupEditing(groupEditing) {
      this.flushEditing();
      this.groupEditing = groupEditing;
    },
    onChangeEventSelection(eventSelection) {
      this.flushEditing();
      this.eventSelection = eventSelection;
    },
    onCategoryChange() {
      if (this.getIsEdit) {
        return;
      }
      if (this.getIsLesson) {
        this.disablePeriod = false;
        return;
      }
      if (this.isAdministrator() && this.getIsLesson) {
        this.disablePeriod = false;
        return;
      }

      this.isSingleEvent = true;
      this.disablePeriod = true;
    },
  },
};
</script>

<style lang="scss">
    .form-group {
        .v-text-field {
            .v-input__append-inner {
                margin-top: 9px;
                i {
                    color: #969696;
                }
            }
            .v-text-field__details {
                margin-bottom: 5px;
                padding-left: 0;
            }
            .v-input__control .v-input__slot {
                padding: 0;
                min-height: 40px;
                box-shadow: none;
                input {
                    padding: 0 12px;
                    font-size: 14px;
                    height: 100%;
                }
            }
            fieldset {
                border-color: $elementColor;
                border-width: 1px;
            }
            &.v-input--is-focused {
                fieldset {
                    border-color: $accentColor;
                }
            }
            &.error--text {
                fieldset {
                    border-color: $dangerColor;
                }
            }
        }
        .v-select {
            .v-select__slot {
                .v-select__selections {
                    padding: 8px 12px !important;
                    font-size: 14px;
                    input {
                        padding: 0;
                    }
                    .v-select__selection {
                        margin: 3px 0;
                    }
                }
            }
        }
    }

    .form-date-group {
        display: flex;
        flex-flow: row wrap;
    }

    .form-date-group__item {
        width: 25%;
        &.form-single-date {
            width: 40%;
        }
        &:not(:nth-child(3)) {
            margin-right: 5%;
        }
    }

    .form-group_date {
        .v-input__append-inner {
            position: absolute;
            right: 8px;
            pointer-events: none;
            .primary--text {
                color: $accentColor !important;
            }
        }
    }

    .form__teachers {
        margin-top: 20px;
    }

    .form-control {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        .form-control__left {
            .m-button {
                margin-right: 25px;
            }
        }
        .form-control__right {
          .m-button {
            margin-left: 25px;
          }
        }
    }

    .v-date-picker-table {
        .v-btn--outlined {
            background: $accentColor;
            color: #fff !important;
        }
        .v-btn--active {
            color: $accentColor !important;
            border: thin solid currentColor;
            background-color: #fff !important;
            &:before {
                background: #fff;
            }
        }
    }

    .recurring-event {
        .col {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .recurring-event__add {
        color: $accentColor;
        text-decoration: underline;
        font-weight: 500;
        font-size: 16px;
        &.disabled {
            color: $elementColor;
            pointer-events: none;
        }
        span {
            cursor: pointer;
        }
    }
</style>
