export default {
  data: () => ({
    showStudentsError: false,
  }),
  computed: {
    getStudents: {
      get() {
        return this.$store.getters['students/getStudents'];
      },
    },
    studentsIsSelected() {
      return this.form.students.length !== 0;
    },
    filteredStudents() {
      let students = [];
      if (this.isStudent()) {
        students = [this.$store.getters['auth/getUser']];
      } else if (this.isTeacher()) {
        students = [];
      } else {
        students = this.$store.getters['students/getStudents'];
      }
      // if one user is a teacher and administrator
      if (this.isAdministrator()) {
        students = this.$store.getters['students/getStudents'];
      }
      return students;
    },
  },
  methods: {
    isTeacher() {
      const { roles } = this.$store.getters['auth/getUser'];
      return (roles.findIndex(role => (role.name === 'teacher')) !== -1);
    },
    isStudent() {
      const { roles } = this.$store.getters['auth/getUser'];
      return (roles.findIndex(role => (role.name === 'student')) !== -1);
    },
    isAdministrator() {
      const { roles } = this.$store.getters['auth/getUser'];
      return (roles.findIndex(role => (role.name === 'administrator')) !== -1);
    },
    isAttendant() {
      const { roles } = this.$store.getters['auth/getUser'];
      return (roles.findIndex(role => (role.name === 'attendant')) !== -1);
    },
    selectStudents(students) {
      this.showStudentsError = false;
      this.form.students = [...students];
      if (this.form.teachers.length > 0) {
        this.showStudentsError = false;
        this.showTeachersError = false;
      }
      if (this.form.students.length > 0) {
        this.showStudentsError = false;
        this.showTeachersError = false;
      }
    },
  },
};
