<template>
    <div class="calendar__controls">
        <div class="calendar__text-start-end-date">
            {{ checkAndGetDate }}
        </div>
        <div class="calendar__controls-change-period" :class="{'disabled-item': isAppLoading}">
            <button
                    class="calendar__button-prev"
                    @click="prevPeriod"
            >
                <i class="fas fa-chevron-left"></i>
            </button>
            <button
                    class="calendar__button-next"
                    @click="nextPeriod">
                <i class="fas fa-chevron-right"></i>
            </button>
        </div>
    </div>
</template>

<script>

const defaultSetting = {
  addDay: 7,
  showDays: 7,
  formatDate: 'DD MMM YYYY',
};

export default {
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
    startDate: {
      type: Object,
      default: () => this.$moment().set({
        hour: 0, minute: 0, second: 0, millisecond: 0,
      }),
    },
  },
  computed: {
    configControl() {
      return Object.assign(defaultSetting, this.settings || {});
    },
    checkAndGetDate() {
      if (this.configControl.showDays === 1) {
        return this.getDate;
      }
      return this.getIntervalDate;
    },
    getDate() {
      return this.getFormatDate(this.startDate);
    },
    getIntervalDate() {
      return `${this.getDate} - ${this.getFormatDate(this.getNextPeriod(this.configControl.addDay - 1))}`;
    },
  },
  methods: {
    getFormatDate(date) {
      return this.$moment(date).format(this.configControl.formatDate);
    },
    getNextPeriod(dayCount) {
      return this.$moment(this.startDate).add(dayCount, 'days');
    },
    getPrevPeriod(dayCount) {
      return this.$moment(this.startDate).subtract(dayCount, 'days');
    },
    nextPeriod() {
      this.$emit('onNextPeriod', this.getNextPeriod(this.configControl.addDay));
    },
    prevPeriod() {
      this.$emit('onPrevPeriod', this.getPrevPeriod(this.configControl.addDay));
    },
  },
};
</script>
