<template>
    <div>
        <v-text-field
                :disabled="disabled"
                v-model="input"
                v-mask="mask"
                :rules="rules"
                :id="customId"
                :placeholder="placeholder"
                outlined
        ></v-text-field>
        <label class="label" :for="customId">
            {{label}}
            <i class="warning-icon">!</i>
        </label>
    </div>
</template>

<script>
import { mask } from 'vue-the-mask';

export default {
  name: 'TimePicker',
  props: {
    label: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    value: {
      type: String,
    },
    rules: {
      type: Array,
    },
    customId: {
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  directives: {
    mask,
  },
  data: () => ({
    mask: '##:##',
  }),
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped>

</style>
