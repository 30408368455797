import constants from '@/constants';

export default {
  data: () => ({
    recurringFormData: {
      start_date: window.app.$moment(new Date()).format(constants.backendDateFormat),
      end_date: '',
      interval: '',
      week_days: [],
    },
    canBack: false,
  }),
  computed: {
    getRecurringData: {
      get() {
        return this.$store.getters['eventForm/getRecurringData'];
      },
    },
    getIsRecurringOnCreating: {
      get() {
        return this.$store.getters['eventForm/getIsRecurringOnCreating'];
      },
    },
    recurringEndDateMin() {
      const startDate = this.recurringFormData.start_date;
      return startDate
        ? this.$moment(startDate, constants.backendDateFormat).add(6, 'days').format(constants.backendDateFormat)
        : this.$moment(new Date(), constants.backendDateFormat).add(6, 'days').format(constants.backendDateFormat);
    },
    isValidRecurring() {
      const {
        start_date: startDate,
        end_date: endDate,
      } = this.recurringFormData;

      const {
        start_time: timeFrom,
        end_time: timeTo,
      } = this.form;

      return startDate && endDate && timeFrom && timeTo;
    },
  },
  methods: {
    changeEditableEvent(id) {
      let answer = true;
      if (this.getIsEdit && this.getIsFormChanged) {
        answer = this._confirmAction(window.app.$t('message.dataCanNotBeSaved'));
      }

      this.canBack = true;

      if (answer) {
        this.$emit('isLoading', true);
        this.$api.events.getById(id).then(async (data) => {
          await this._setEvent(data);
          await this.setFormData();
          await this.$refs.students.setValue();
          this.isSingleEvent = true;
          this.$store.dispatch('eventForm/setFormChanged', false);
        }).finally(() => {
          this.$emit('isLoading', false);
        });
      }
    },
    createRecurringEvents() {
      if (this.getRecurringEvents.length > 0) {
        if (!this._confirmAction(window.app.$t('message.doYouWantToChangeAListRecurrence'))) {
          return;
        }
      }

      if (this.isFormValid()) {
        this.$emit('createRecurringEvents', {
          ...this.form,
          ...this.recurringFormData,
          students: [...this.form.students.map(item => item.id)],
          teachers: [...this.form.teachers.map(item => item.id)],
        });
        this.$store.dispatch('eventForm/setIsRecurringEventOnCreating', true);
        this.$store.dispatch('eventForm/saveRecurringData', {
          form: {
            id: null,
            event_name: this.form.event_name,
            category_id: this.form.category_id,
            status_id: this.form.status_id,
            subject_id: this.form.subject_id,
            room_id: this.form.room_id,
            teachers: [...this.form.teachers],
            students: [...this.form.students],
            start_time: this.form.start_time,
            end_time: this.form.end_time,
            start_date: this.form.start_date,
            errors: { ...this.form.errors },
          },
          recurringFormData: { ...this.recurringFormData },
        });
      }
    },
    backToRecurring() {
      if (this.getIsFormChanged) {
        if (!this._confirmAction(window.app.$t('message.dataCanNotBeSaved'))) {
          return;
        }
      }
      this.canBack = false;
      this.isSingleEvent = false;
      const { form, recurringFormData } = this.getRecurringData;
      this.form = { ...form };
      this.recurringFormData = { ...recurringFormData };
      setTimeout(() => {
        this.$refs.students.setValue();
        this.$refs.interval.setValue({
          days: recurringFormData.week_days,
          interval: recurringFormData.interval,
        });
        this.$store.dispatch('eventForm/setFormChanged', false);
      }, 100);
    },
  },
};
