<template>
    <div class="interval-wrapper form-group">
        <label for="">Interval</label>
        <div class="interval">
            <div class="interval__select">
                <v-select
                        :items="options"
                        v-model="interval"
                        @change="onIntervalChange()"
                        id="category"
                        outlined
                ></v-select>
            </div>
            <div class="interval__days">
                <button
                        v-for="{title, checked, id} in days"
                        @click.prevent="onDaysChange(id)"
                        :class="['days__item', checked ? 'active' : '']"
                        :key="id"
                >
                    {{title.charAt(0)}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Interval',
  data: () => ({
    interval: 1,
    options: [
      {
        text: window.app.$t('message.weekly'),
        value: 1,
      },
      {
        text: window.app.$t('message.everySecondWeek'),
        value: 2,
      },
      {
        text: window.app.$t('message.everyThirdWeek'),
        value: 3,
      },
      {
        text: window.app.$t('message.monthly'),
        value: 4,
      },
    ],
    days: [
      {
        title: window.app.$t('message.monday'),
        checked: false,
        id: 1,
      },
      {
        title: window.app.$t('message.thuesday'),
        checked: true,
        id: 2,
      },
      {
        title: window.app.$t('message.wednesday'),
        checked: false,
        id: 3,
      },
      {
        title: window.app.$t('message.thursday'),
        checked: false,
        id: 4,
      },
      {
        title: window.app.$t('message.friday'),
        checked: true,
        id: 5,
      },
      {
        title: window.app.$t('message.saturday'),
        checked: false,
        id: 6,
      },
      {
        title: window.app.$t('message.sunday'),
        checked: false,
        id: 0,
      },
    ],
  }),
  mounted() {
    this.onDaysChange();
    this.onIntervalChange();
  },
  computed: {
    checkedDays() {
      return this.days
        .map(({ checked, id }) => (checked ? id : null))
        .filter(item => item || item === 0);
    },
    isLastChecked() {
      return this.days.filter(({ checked }) => checked).length === 1;
    },
  },
  methods: {
    onDaysChange(id) {
      this.days.map((day) => {
        if (day.id === id) {
          if (this.isLastChecked && day.checked) {
            // eslint-disable-next-line
            day.checked = true;
          } else {
            // eslint-disable-next-line
            day.checked = !day.checked;
          }
        }
        return day;
      });
      this.$emit('onDaysChange', this.checkedDays);
    },
    setValue({ interval, days }) {
      this.interval = interval;
      this.days = [...this.days.map(item => (
        days.indexOf(item.id) !== -1
          ? { ...item, checked: true }
          : { ...item, checked: false })),
      ];
      this.$emit('onIntervalChange', this.interval);
      this.$emit('onDaysChange', this.checkedDays);
    },
    onIntervalChange() {
      this.$emit('onIntervalChange', this.interval);
    },
  },
};
</script>

<style lang="scss">
    .interval-wrapper{
        padding-top: 0;
        label{
            color: $defaultColor;
            font-size: 12px;
            margin-bottom: 8px;
            display: block;
        }
        .v-text-field__details{
            display: none !important;
        }
    }
    .interval{
        display: flex;
        align-items: flex-start;
        width: 100%;
        flex-direction: column;
    }
    .interval__select{
        flex-basis: 100%;
        width: 100%;
        padding-right: 0;
    }
    .interval__days{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 7px;
        width: 70%;
        margin-bottom: 10px;
    }
    .days__item{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 24px;
        width: 24px;
        height: 24px;
        border: 1px solid $elementColor;
        border-radius: 50%;
        font-size: 12px;
        text-transform: uppercase;
        color: $defaultColor;
        &.active{
            background-color: $accentColor;
            color: #ffff;
            border-color: $accentColor;
        }
    }
</style>
