<template>
  <div class="students">
    <label class="label" v-if="label">
      {{ label }}<i class="warning-icon" v-if="hasErrors()">!</i>
    </label>
    <div class="selections-list" v-show="getGroupedStudents.groups.length > 0">
      <div
          v-for="(item, index) in getGroupedStudents.groups"
          :key="item.id || index"
          class="selections-list__item"
      >
        <span>
          {{ item.name }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <i class="warning-icon" v-if="isErrorGroup(item.id)">!</i>
              </span>
            </template>
            <span>{{ groupError(item.id) }}</span>
          </v-tooltip>
        </span>
      </div>
    </div>
    <div class="selections-list" v-show="getGroupedStudents.students.length > 0">
      <div
          v-for="(item, index) in getGroupedStudents.students"
          :key="item.id || index"
          class="selections-list__item"
      >
        <span>
          {{ item.full_name }}
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <i class="warning-icon" v-if="isErrorStudent(item.id)">!</i>
              </span>
            </template>
            <span>{{ studentError(item.id) }}</span>
          </v-tooltip>
        </span>
      </div>
    </div>

        <tree-view
          ref="tree"
          :disabled="isAppLoading || disabled"
          :items="students"
          label=""
          :errors="errors"
          :showError="showError"
          itemChildren="group_students"
          :selectedItems="selectedStudents"
          :itemLabel="itemLabel"
          @onSelect="setSelectedStudents"
        />
    </div>
</template>

<script>
import TreeView from '@/components/Shared/TreeView.vue';

export default {
  name: 'StudentsTree',
  props: {
    showError: {
      default: () => false,
    },
    disabled: {
      default: () => false,
    },
    label: {
      default: () => false,
    },
    errors: {
      default: () => {},
    },
    itemLabel: {
      type: String,
      default: 'name',
    },
    students: {
      type: Array,
      default: () => [],
    },
    selectedStudents: {
      type: Array,
    },
  },
  components: {
    TreeView,
  },
  data: () => ({
    search: '',
  }),
  computed: {
    getGroupedStudents() {
      const selectedGroups = [];

      /* eslint-disable */
      this.selectedStudents.map(selectedStudent => {
        let studentGroup = [];
        if(typeof this.students[0].group_students !== 'undefined'){
          for (let i = 0; i < this.students.length; i += 1) {
            studentGroup = this.students[i].group_students.findIndex(
              group => group.id === selectedStudent.id
            );
            if (studentGroup > -1) {
              studentGroup = this.students[i];
              break;
            }
          }
        }else{
           studentGroup =
            this.students.findIndex(student => selectedStudent.id === student.id);
        }
        if (studentGroup) {
          let index = selectedGroups.findIndex(item => item.id === studentGroup.id);
          // let index = studentGroup;
          if (index < 0) {
            selectedGroups.push({ id: studentGroup.id, name: studentGroup.name, students: [] });
            index = selectedGroups.findIndex(item => item.id === studentGroup.id);
          }
          selectedGroups[index].students.push(selectedStudent);
        }
      });
      /* eslint-enable */
      const filledGroups = [];
      let singleStudents = [];


      singleStudents = [];
      selectedGroups.map((group) => {
        const selectedStudentGroup = this.students.find(item => group.id === item.id);

        if (selectedStudentGroup) {
          filledGroups.push(group);
          if (group.students.length) {
            for (let i = 0; i < group.students.length; i += 1) {
              singleStudents.push(group.students[i]);
            }
          }
          // singleStudents = [...singleStudents, ...group.students];
        }
        return 0;
      });
      return { groups: filledGroups, students: singleStudents };
    },
  },
  methods: {
    setSelectedStudents(students) {
      this.$emit('onSelect', students);
    },
    setValue() {
      this.$refs.tree.setValue();
    },
    isErrorGroup(groupId) {
      let error = false;
      const group = this.students.find(g => g.id === groupId);
      group.group_students.map((student) => {
        if (this.isErrorStudent(student.id)) {
          error = true;
        }
        return 0;
      });
      return error;
    },
    groupError(groupId) {
      let error = null;
      const group = this.students.find(g => g.id === groupId);
      group.group_students.map((student) => {
        if (this.isErrorStudent(student.id)) {
          error = this.studentError(student.id);
        }
        return null;
      });
      return error;
    },
    isErrorStudent(id) {
      if (this.errors !== undefined) {
        return this.errors
          ? Object.keys(this.errors).indexOf(String(id)) > -1 : false;
      }
      return false;
    },
    studentError(id) {
      if (this.errors !== undefined && this.errors !== null) {
        if (this.errors[id] !== undefined) {
          return this.$moment(this.errors[id].reservation[0].start_datetime, 'YYYY-MM-DD HH:mm:ss')
            .format('ddd DD.MM.YYYY HH:mm');
        }
      }
      return null;
    },
    hasErrors() {
      if (this.errors?.length) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">

</style>
